import { Col, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import { BASE_URL } from "../../baseurl";
import { useTranslation } from "react-i18next";
import Loading from "../loading";

const Percentage = () => {
  const [updatedPercentage, setUpdatedPercentage] = useState("");
  const [load, setLoad] = useState(false);
  const [data, setData] = useState();
  const [id, setId] = useState('')
  const { t } = useTranslation();
  let API_URL = `${BASE_URL}/api/percentages`;

  useEffect(() => {
    setLoad(true)

    fetch(API_URL)
      .then((response) => {
        if(!response.ok){
          throw new Error("Network response was not ok")
        }
        return response.json()
      })
      .then((data) => {
        setData(data);
        setLoad(false);
      })
      .catch((error) => {
        console.error("There was a problem fetching the data", error);
        setLoad(false)
      })
  },[])

  const renew = () => {
    setLoad(true)

    fetch(API_URL)
      .then((response) => {
        if(!response.ok){
          throw new Error("Network response was not ok")
        }
        return response.json()
      })
      .then((data) => {
        setData(data);
        setLoad(false);
      })
      .catch((error) => {
        console.error("There was a problem fetching the data", error);
        setLoad(false)
      })
  }
  

  const handleUpdatePercentage = async () => {
    const formData = new FormData();
    formData.append("data", JSON.stringify({ ...data, perc: updatedPercentage }));
    const response = await fetch(`${BASE_URL}/api/percentages/1`, {
      method: "PUT",
      body: formData,
    });
    if (response.ok) {
      console.log("Category created successfully!");
      renew()
      setUpdatedPercentage('')
    } else {
      console.error("Error creating category:", response.statusText);
      setUpdatedPercentage('')
      renew()
    }
  };

  return (
    <div>
      {load && <Loading />}
      <form className="form">
        <Row>
          <Col className="d-flex">
            <div>
              <label htmlFor="category">{t('percentageChange')}</label>
              <br />
              <input
              type="number"
                placeholder={`${data?.data?.map((e) => e?.attributes?.perc)} %`}
                value={updatedPercentage}
                onChange={(e) => setUpdatedPercentage(e.target.value)}
              />
            </div>
          </Col>
        </Row>
        <Row className="margin-top-60 btn-wrapper-upload">
          <button
            type="button"
            className="shadow-upload-button"
            onClick={handleUpdatePercentage}
          >
            {t('submit')}
          </button>
        </Row>
      </form>
    </div>
  );
};

export default Percentage;
