import { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
// import { Cross as Hamburger } from 'hamburger-react'
import { Link, useLocation } from "react-router-dom";
import Logo from '../../pictures/logo.png'
import Geo from '../../pictures/language/geo.svg'
import United from '../../pictures/language/united.svg'
import Russia from '../../pictures/language/russia.svg'
import Food from '../../pictures/menu/foods.svg'
import Drink from '../../pictures/menu/drink.svg'
import Catalog from "../catalog";
import HeroSection from "../herosection/HeroSection";
import Cart from '../../pictures/menu/cart.svg';
import Menu from '../../pictures/menu/menu.svg';
import { setDefaults, useTranslation } from 'react-i18next';
import Loading from "../loading/Loading";
import useFetch from "../../hooks/useFetch";
import { BASE_URL } from "../../baseurl";

const Navbar = ({wishlist, setCartAppear, cartAppear, setLanguage, language, setCatalogId}) => {
    // const [isOpen, setOpen] = useState(false);
    const [fix, setFix] = useState(false);
    const location = useLocation();
    const isCurrent = (path) => location.pathname === path;
    const cartOpenHandler = () => {
        setCartAppear(!cartAppear);
    }
    const setFixed = () => {
        if (window.scrollY >= 1) {
            setFix(true)
        } else {
            setFix(false)
        }
    }
    window.addEventListener('scroll', setFixed);

    const toTheMenu = () => {
        const element = document.querySelector(".hero-section div");
        // const el = document.querySelector(".scrollmenu")
        const targetTop = element.offsetTop + element.scrollHeight + 20;
        window.scrollTo({
            top: targetTop,
            left: 0,
            behavior: 'smooth'
        });
    }

    const { t, i18n } = useTranslation();

    const languageHandler = (languageCode) => {
        i18n.changeLanguage(languageCode);
      }
     
    //   const [load, setLoad] = useState(true);

     
        // setTimeout(() => {
        //     setLoad(false);
        //   }, 4000)
    

      const loaderHandler = () => {
        // setLoad(true);
        setCatalogId()
        setTimeout(toTheMenu, 500)
      }

      const {data, loading, error} = useFetch(`${BASE_URL}/api/logos?populate=*`)
      const logo = data?.data;
      const img  = logo?.map((e) => e?.attributes?.img?.data?.attributes?.url);

      const datEn = useFetch(`${BASE_URL}/api/foods?locale=en&populate=*`)
    //   console.log(datEn?.data?.data?.length === 0)
      const datRu = useFetch(`${BASE_URL}/api/foods?locale=ru&populate=*`)
    //   console.log(datRu?.data?.data?.length === 0)
      const datKa = useFetch(`${BASE_URL}/api/foods?locale=ka&populate=*`)
    //   console.log(datKa?.data?.data?.length === 0)
      

    return (
        <>
        {loading && <Loading />}
            <nav className={fix ? 'navbar-fixed' : ''}>
                <Container>
                    <Row>
                        <Col>
                            <div className="language">
                                {datKa?.data?.data?.length === 0 ? "" : <img style={language === '0' ? {scale:'1.3', transition:'0.2s ease'} : {scale:'1', transition:'0.2s ease'}} src={Geo} onClick={() => {setLanguage('0'); languageHandler('ge')}} alt="geo" />}
                                {datEn?.data?.data?.length === 0 ? "" : <img style={language === '1' ? {scale:'1.3', transition:'0.2s ease', padding:'0 10px'} : {scale:'1', transition:'0.2s ease', padding:'0 10px'}} onClick={() => {setLanguage('1'); languageHandler('en')}} src={United} alt="geo" />}
                                {datRu?.data?.data?.length === 0 ? "" : <img className={datEn?.data?.data?.length === 0 ? "marginleft" : ""} style={language === '2' ? {scale:'1.3', transition:'0.2s ease'} : {scale:'1', transition:'0.2s ease'}} src={Russia} alt="geo" onClick={() => {setLanguage('2'); languageHandler('ru')}} />}
                            </div>
                        </Col>
                        <Col>
                            <Link to={'/'}>
                                <div className="logo-size">
                                    <img src={`${BASE_URL}${img}`} alt="logo" />
                                </div>
                            </Link>
                        </Col>
                        <Col className='cart'>
                            {/* <Hamburger toggled={isOpen} toggle={setOpen} size={30} duration={0.5} color='#7F6D47' />
                            <p>მენიუ</p> */}
                            <img onClick={() => cartOpenHandler()} src={Cart} alt="cart" />
                            <p className={wishlist.length ? 'pulse' : 'd-none'}>{wishlist.length}</p>
                        </Col>
                    </Row>
                </Container>
            </nav>
          
            <div className={fix ? "menu-list hidden" : "menu-list hidden-active"}>
            <Container>
                <Row>
                    <Col>
                        <Link className={isCurrent('/') ? 'current' : ''}  to='/'>
                            <div onClick={() => loaderHandler()}>
                                <img src={Menu} alt="menu" />
                                <p className="menu">{t('menu')}</p>
                            </div>
                        </Link>
                    </Col>
                    <Col>
                        <Link className={isCurrent('/product/foods') ? 'current' : ''} to='product/foods'>
                            <div onClick={() => loaderHandler()}>
                                <img src={Food} alt="food" />
                                <p>{t('foods')}</p>
                            </div>
                        </Link>
                    </Col>
                    <Col>
                        <Link className={isCurrent('/product/beverage') ? 'current' : ''} to='product/beverage'>
                            <div onClick={() => loaderHandler()}>
                                <img src={Drink} alt="food" />
                                <p>{t('beverage')}</p>
                            </div>
                        </Link>
                    </Col>
                </Row>
            </Container>
        </div>
            <HeroSection language={language} />
            <Catalog loaderHandler={loaderHandler} language={language} setCatalogId={setCatalogId} />
            </>
     
    )
}

export default Navbar