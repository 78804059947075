import React, { useEffect, useState } from "react";
import { Container, Row, Col, NavLink } from "react-bootstrap";
import Geo from '../../pictures/language/geo.svg'
import United from '../../pictures/language/united.svg'
import Russia from '../../pictures/language/russia.svg'
import Logout from '../../pictures//menu/logout.svg';
import Arrowdown from '../../pictures/menu/arrowdown.svg'
import { Foodredact, Uploadcategory, Uploadfoods, CategoryRedact, LogoChange } from '../../components';
import i18n from "../../core/i18n";
import { useTranslation } from "react-i18next";
import HeroSectPhoto from "../herosectionPhoto/HeroSectPhoto";
import Percentage from "../percentage";
import Content from "../content";

const Uploadnavbar = ({loading, setLoading, language, setLanguage}) => {
    const [isOpen, setOpen] = useState(false)
    const [showUploadForm, setShowUploadForm] = useState("0");
    
    const {t} = useTranslation()
    useEffect(() => {
        if (localStorage.getItem('accessToken') === null) {
          window.location.href = `http://${window.location.host}/login`
        }
      }, [], 10);
      const showUploadCategory = () => {
        setOpen(!isOpen);
        setShowUploadForm("0")
      }
      const showUploadFoods = () => {
        setOpen(!isOpen);
        setShowUploadForm("1")
      }
      const showFoodRedact = () => {
        setOpen(!isOpen);
        setShowUploadForm("2")
      }
      const showCategoryRedact = () => {
        setOpen(!isOpen);
        setShowUploadForm("3")
      }
      const showHeroSectionPhoto = () => {
        setOpen(!isOpen);
        setShowUploadForm("4")
      }
      const showLogoChange = () => {
        setOpen(!isOpen);
        setShowUploadForm("5")
      }
      const showPercentage = () => {
        setOpen(!isOpen);
        setShowUploadForm("6")
      }
      const showContent = () => {
        setOpen(!isOpen);
        setShowUploadForm("7")
      }

    const logout = () => {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        if (localStorage.getItem('accessToken') === null) {
            window.location.href = `http://${window.location.host}/login`
        }
    }

    const languageHandler = (languageCode) => {
      i18n.changeLanguage(languageCode);
    }

  return (
    <>
      <Container className="uploadnavbar" fluid>
        <Container>
          <Row>
            <Col>
              <div className="wrapper">
                <div onClick={() => setOpen(!isOpen)} className="select-btn">
                    <span>{showUploadForm === '0' ? t('addCategory') : '' || showUploadForm === "1" ? t('addProduct') : '' || showUploadForm === '2' ? t('productRedact') : '' || showUploadForm === '3' ? t("categoryRedact") : '' || showUploadForm === '4' ? t("herosection") : '' || showUploadForm === '5' ? t("logochange") : '' || showUploadForm === '6' ? t("percentageChange") : '' || showUploadForm === '7' ? t("content") : ''}</span>
                    <i className={isOpen ? "arrowdown-open": "arrowdown"}><img src={Arrowdown} alt="arrow" /></i>
                </div>
                <div className={isOpen ? "content show-dropdown": "content"}>
                <ul className="options">
                    <li onClick={() => showUploadCategory()}>{t('addCategory')}</li>
                    <li onClick={() => showUploadFoods()}>{t('addProduct')}</li>
                    <li onClick={() => showFoodRedact()}>{t('productRedact')}</li>
                    <li onClick={() => showCategoryRedact()}>{t('categoryRedact')}</li>
                    <li onClick={() => showHeroSectionPhoto()}>{t('herosection')}</li>
                    <li onClick={() => showLogoChange()}>{t('logochange')}</li>
                    <li onClick={() => showPercentage()}>{t('percentageChange')}</li>
                    <li onClick={() => showContent()}>{t('content')}</li>
                </ul>
                </div>
              </div>
            </Col>
            <Col>
              {/* <img src={Logo} alt="logo" /> */}
              <div className="language" style={{cursor:"pointer"}}>
                                <img style={language === '0' ? {scale:'1.3', transition:'0.2s ease'} : {scale:'1', transition:'0.2s ease'}} src={Geo} onClick={() => {setLanguage('0'); languageHandler('ge')}} alt="geo" />
                                <img style={language === '1' ? {scale:'1.3', transition:'0.2s ease', padding:'0 10px'} : {scale:'1', transition:'0.2s ease', padding:'0 10px'}} onClick={() => {setLanguage('1'); languageHandler('en')}} src={United} alt="geo" />
                                <img style={language === '2' ? {scale:'1.3', transition:'0.2s ease'} : {scale:'1', transition:'0.2s ease'}} src={Russia} alt="geo" onClick={() => {setLanguage('2'); languageHandler('ru')}} />
                            </div>
            </Col>
            <Col>
            <img onClick={() => logout()} src={Logout} alt="logout" />
            </Col>
          </Row>
        </Container>
      </Container>
      <Container className="uploadwrapper">
        <div style={{display:'flex', justifyContent:'end', marginBottom:'2rem'}}><a style={{padding:"0.5rem", background:'rgb(127, 109, 71)', borderRadius:'6px', color:'#f7f0e8', border:"1px solid #f7f0e8",}} href="/">{t("tothemain")}</a></div>
        {showUploadForm === "0" && <Uploadcategory language={language} loading={loading} setLoading={setLoading} />}
        {showUploadForm === "1" &&<Uploadfoods language={language} loading={loading} setLoading={setLoading} />}
        {showUploadForm === "2" && <Foodredact language={language} loading={loading} setLoading={setLoading} />}
        {showUploadForm === "3" && <CategoryRedact language={language} />}
        {showUploadForm === "4" && <HeroSectPhoto language={language} />}
        {showUploadForm === "5" && <LogoChange language={language} />}
        {showUploadForm === "6" && <Percentage language={language} />}
        {showUploadForm === "7" && <Content language={language} />}
      </Container>
    </>
  );
};

export default Uploadnavbar;
