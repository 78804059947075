import { Container, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Cards, Loading } from "../components";
import { BASE_URL } from "../baseurl";
// import { foods } from '../data'
import useFetch from "../hooks/useFetch";
import { useEffect, useState } from "react";

const Main = ({ wishlist, setWishlist, language, catalogId}) => {
  const [fechedData, setFechedData] = useState()
  let URL = `${BASE_URL}/api/foods?populate=*`;
  if (language === "1") {
    URL = `${BASE_URL}/api/foods?locale=en&populate=*`;
  } else if (language === "2") {
    URL = `${BASE_URL}/api/foods?locale=ru&populate=*`;
  }

  if(window.location.href.includes('/product/beverage')){
    URL = `${BASE_URL}/api/foods?filters[categorys][sort][$eq]=false&populate=*`;
    if (language === "1") {
      URL = `${BASE_URL}/api/foods?locale=en&filters[categorys][sort][$eq]=false&populate=*`;
    } else if (language === "2") {
      URL = `${BASE_URL}/api/foods?locale=ru&filters[categorys][sort][$eq]=false&populate=*`;
    }
  }
  if(window.location.href.includes('/product/foods')){
    URL = `${BASE_URL}/api/foods?filters[categorys][sort][$eq]=true&populate=*`;
    if (language === "1") {
      URL = `${BASE_URL}/api/foods?locale=en&filters[categorys][sort][$eq]=true&populate=*`;
    } else if (language === "2") {
      URL = `${BASE_URL}/api/foods?locale=ru&filters[categorys][sort][$eq]=true&populate=*`;
    }
  }

  // console.log(catalogId)
  // console.log(catalogId)
  // let URL = `${BASE_URL}/api/foods?populate=*`;
  // if (language === "1") {
  //   URL = `${BASE_URL}/api/foods?locale=en&populate=*`;
  // } else if (language === "2") {
  //   URL = `${BASE_URL}/api/foods?locale=ru&populate=*`;
  // }

  let { data, loading, error } = useFetch(URL);
  let text = useFetch(`${BASE_URL}/api/categories/${catalogId}?populate[foods][populate]=*`)
  // console.log('test', text?.data?.data?.attributes?.foods?.data)
  useEffect(() => {
    if (catalogId) {
      setFechedData(text?.data?.data?.attributes?.foods?.data);
    } else {
      setFechedData(data?.data);
    }
  }, [data, text, catalogId]);
  // console.log(data.data, "dasdkajsodajsodasjodasiodamsodmaos");
  // console.log(fechedData)

  // let searched = text?.data?.data?.attributes?.foods?.data
  
  const { t } = useTranslation();
  const foodsTranslation = t("foods");
  const beverageTranslation = t("beverage");
  const all = t("all");
  const pathName = window.location.pathname;

const content =
  pathName === '/' ? `${foodsTranslation} / ${beverageTranslation}` :
  pathName === '/product/foods' ? foodsTranslation :
  pathName === '/product/beverage' ? beverageTranslation :
  '';


  // console.log(text?.data?.data?.attributes?.title, "dasdasda")

  return (
    <>
      <main>
          {loading && <Loading />}
          {/* {loadin && <Loading />} */}
          <>
            <Container className="distinctive">
              <Row>
                <Col>
                  <p>
                    {content} / <span> {text?.data?.data?.attributes?.title === undefined ? all : text?.data?.data?.attributes?.title}</span>
                  </p>
                </Col>
              </Row>
            </Container>
            <Container>
              <Row className="pb-100" xs={2} md={2} lg={3} xl={4} xxl={4}>
                {fechedData?.map((item, key) => (
                  <Cards
                    item={item}
                    key={key}
                    setWishlist={setWishlist}
                    wishlist={wishlist}
                  />
                ))}
              </Row>
            </Container>
          </>
        </main>
    </>
  );
};

export default Main;
