// import { useState } from "react";
// import { Col } from "react-bootstrap";
// import { useTranslation } from "react-i18next";

// const Cards = ({ item, setWishlist, wishlist }) => {
//   const [checkedValue, setCheckedValue] = useState(null);
//   // const addWishlistHandler = () => {
//   //   let newItem = {...item};
//   //   setWishlist([...wishlist, item]);
//   //   if (checkedValue !== null) {
//   //     newItem.attributes.price = checkedValue;
//   //   }
//   //   setWishlist([...wishlist, newItem]);
//   // };

//   const {t} = useTranslation();

//   const addWishlistHandler = () => {
//     let newItem = {...item};
//     if (checkedValue !== null) {
//       newItem.attributes = {...newItem.attributes, price: checkedValue};
//     }
//     setWishlist([...wishlist, newItem]);
//   };

//   const checkedUncheckedHandler = (e) => {
//         const checkboxes = document.querySelectorAll('.checkbox-container input[type="checkbox"]');
//         checkboxes.forEach((checkbox) => {
//             if (checkbox !== e.target && checkbox.checked) {
//                 checkbox.checked = false;
//             } else if (checkbox === e.target && checkbox.checked) {
//                 setCheckedValue(Number(checkbox.value));
//               }
//         });
//   }

//   return (
//     <>
//       <Col>
//         <div className="card mb-24">
//           <img src={`${BASE_URL}${item.attributes.img.data.attributes.url}`} alt="Avatar" />
//           <div className="container">
//             <div className="space-between">
//               <p>{item.attributes.title}</p>
//               <p>{checkedValue === null ? item.attributes.price : checkedValue}{t('currency')}</p>
//             </div>
//             <p className="mt-12 weight-500">{item.attributes.description}</p>
//             {item.attributes.anotherfeature && (
//                 <div className="mt-12" style={{flexDirection:'column'}}>
//                 {item.attributes.anotherfeature.map((el, key) => (
//                     <div className="checkbox-container" key={key}>
//                     <input id="cb01" type='checkbox' value={el.price} defaultChecked={key === 0} onChange={checkedUncheckedHandler} />
//                     <label>{el.title}</label>
//                     </div>
//                 ))}
//                 </div>
//             )}
//             <button className="addBtn" onClick={() => addWishlistHandler()}>
//               {t('addcard')}
//             </button>
//           </div>
//         </div>
//       </Col>
//     </>
//   );
// };

// export default Cards;

import { useState } from "react";
import { Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { BASE_URL } from "../../baseurl";
import useFetch from "../../hooks/useFetch";

const Cards = ({ item, setWishlist, wishlist }) => {
  const [checkedValues, setCheckedValues] = useState({});
  const { t } = useTranslation();

  const addWishlistHandler = () => {
    let newItem = { ...item };
    const selectedPrice =
      checkedValues[item.id] !== undefined ? checkedValues[item.id] : null;
    if (selectedPrice !== null) {
      newItem.attributes = { ...newItem.attributes, price: selectedPrice };
    }
    setWishlist([...wishlist, newItem]);
  };

  const checkedUncheckedHandler = (e, itemId) => {
    const selectedValue = Number(e.target.value);
    setCheckedValues((prevValues) => ({
      ...prevValues,
      [itemId]: e.target.checked ? selectedValue : null,
    }));
  };

  const data = useFetch(`${BASE_URL}/api/logos?populate=*`);
  const logo = data?.data?.data;
  const img = logo?.map((e) => e.attributes.img.data.attributes.url);
  // console.log(img)

  // console.log(item.attributes, "adasdas")

  return (
    <>
      <Col style={{ display: item.attributes.hide ? "none" : "",  marginBottom: "30px" }}>
        <div
          className="card mb-24"
          
        >
          <img
            src={`${BASE_URL}${
              item?.attributes?.img?.data?.attributes?.url == null
                ? img
                : item?.attributes?.img?.data?.attributes?.url
            }`}
            alt="Avatar"
          />
          <div className="container">
            <div className="space-between">
              <p>{item.attributes.title}</p>
              <p>
                {checkedValues[item.id] !== undefined
                  ? checkedValues[item.id]
                  : item.attributes.price}
                {t("currency")}
              </p>
            </div>
            <p className="mt-12 weight-500">{item.attributes.description}</p>

            <div className="mt-12" style={{ flexDirection: "column" }}>
              {item.attributes.small && (
                <div className="checkbox-container" key={`${item.id}_small`}>
                  <input
                    id={`cb_${item.id}_small`}
                    type="checkbox"
                    value={item.attributes.smallprice}
                    checked={
                      checkedValues[item.id] === item.attributes.smallprice
                    }
                    onChange={(e) => checkedUncheckedHandler(e, item.id)}
                  />
                  <label htmlFor={`cb_${item.id}_small`}>
                    {item.attributes.small}
                  </label>
                </div>
              )}

              {item.attributes.medium && (
                <div className="checkbox-container" key={`${item.id}_medium`}>
                  <input
                    id={`cb_${item.id}_medium`}
                    type="checkbox"
                    value={item.attributes.mediumprice}
                    checked={
                      checkedValues[item.id] === item.attributes.mediumprice
                    }
                    onChange={(e) => checkedUncheckedHandler(e, item.id)}
                  />
                  <label htmlFor={`cb_${item.id}_medium`}>
                    {item.attributes.medium}
                  </label>
                </div>
              )}

              {item.attributes.big && (
                <div className="checkbox-container" key={`${item.id}_big`}>
                  <input
                    id={`cb_${item.id}_big`}
                    type="checkbox"
                    value={item.attributes.bigprice}
                    checked={
                      checkedValues[item.id] === item.attributes.bigprice
                    }
                    onChange={(e) => checkedUncheckedHandler(e, item.id)}
                  />
                  <label htmlFor={`cb_${item.id}_big`}>
                    {item.attributes.big}
                  </label>
                </div>
              )}
            </div>

            <button className="addBtn" onClick={addWishlistHandler}>
              {t("addcard")}
            </button>
          </div>
        </div>
      </Col>
    </>
  );
};

export default Cards;

// import { useState } from "react";
// import { Col } from "react-bootstrap";
// import { useTranslation } from "react-i18next";
// import {BASE_URL} from '../../baseurl'
// import useFetch from "../../hooks/useFetch";

// const Cards = ({ item, setWishlist, wishlist }) => {
//   const [checkedValues, setCheckedValues] = useState({});
//   const { t } = useTranslation();

//   const addWishlistHandler = () => {
//     let newItem = { ...item };
//     const selectedPrice = checkedValues[item.id] !== undefined ? checkedValues[item.id] : null;
//     if (selectedPrice !== null) {
//       newItem.attributes = { ...newItem.attributes, price: selectedPrice };
//     }
//     setWishlist([...wishlist, newItem]);
//   };

//   const checkedUncheckedHandler = (e, itemId) => {
//     const selectedValue = Number(e.target.value);
//     setCheckedValues((prevValues) => ({
//       ...prevValues,
//       [itemId]: e.target.checked ? selectedValue : null,
//     }));
//   };

//   const data = useFetch(`${BASE_URL}/api/logos?populate=*`)
//           const logo = data?.data?.data;
//           const img  = logo?.map((e) => e.attributes.img.data.attributes.url);
//           // console.log(img)

//           console.log(item.attributes, "adasdas")

//   return (
//     <>
//       <Col>
//         <div className="card mb-24" style={{ display: item.attributes.hide ? "none" : "" }}>
//           <img src={`${BASE_URL}${item?.attributes?.img?.data?.attributes?.url == null ? img : item?.attributes?.img?.data?.attributes?.url}`} alt="Avatar" />
//           <div className="container">
//             <div className="space-between">
//               <p>{item.attributes.title}</p>
//               <p>
//                 {checkedValues[item.id] !== undefined ? checkedValues[item.id] : item.attributes.price}
//                 {t('currency')}
//               </p>
//             </div>
//             <p className="mt-12 weight-500">{item.attributes.description}</p>
//             {item.attributes.anotherfeature && (
//               <div className="mt-12" style={{ flexDirection: 'column' }}>
//                 {item.attributes.anotherfeature.map((el, key) => (
//                   <div className="checkbox-container" key={key}>
//                     <input
//                       id={`cb_${item.id}_${key}`}
//                       type="checkbox"
//                       value={el.price}
//                       checked={checkedValues[item.id] === el.price}
//                       onChange={(e) => checkedUncheckedHandler(e, item.id)}
//                     />
//                     <label htmlFor={`cb_${item.id}_${key}`}>{el.title}</label>
//                   </div>
//                 ))}
//               </div>
//             )}
//             <button className="addBtn" onClick={addWishlistHandler}>
//               {t('addcard')}
//             </button>
//           </div>
//         </div>
//       </Col>
//     </>
//   );
// };

// export default Cards;
