import { useParams } from "react-router-dom"
import { Container, Row, Col } from 'react-bootstrap'
import { Cards } from "../components"
// import { foods } from '../data';
import useFetch from '../hooks/useFetch';
import { useTranslation } from "react-i18next";
import { BASE_URL } from "../baseurl";

const FilteredProducts = ({ wishlist, setWishlist, language, catalogId }) => {
  let URL = `${BASE_URL}/api/foods?populate=*`;
        if (language === '1') {
             URL = `${BASE_URL}/api/foods?locale=en&populate=*`;
            } else if (language === '2') {
             URL = `${BASE_URL}/api/foods?locale=ru&populate=*`;
        }
  let { data } = useFetch(URL);
  const { productId } = useParams()
  const { categoryId } = useParams();
  const { t } = useTranslation();
  
  let categoryName = ''
  data.data?.filter((e) => e.attributes.link === productId)?.map((e) => {
    categoryName = [e.attributes.category]
    return e.attributes.name;
  });

  // console.log(catalogId)
  
  return (
    <>
      <main>
        <Container className='distinctive'>
          <Row>
            <Col>
              <p>{categoryId === "beverage" ? `${t('beverage')} /` : `${t('foods')} /`}<span> {categoryName[0]}</span></p>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row className="pb-100" xs={2} md={2} lg={3} xl={4} xxl={4}>
            {data.data?.filter((e) => e.attributes.link === productId).map((item, key) =>
              <Cards item={item} key={key} setWishlist={setWishlist} wishlist={wishlist} />
            )}
            asdasdasda
          </Row>
        </Container>
      </main>
    </>
  )
}

export default FilteredProducts