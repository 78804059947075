import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import { MdCloudUpload, MdDelete } from "react-icons/md";
import { AiFillFileImage } from "react-icons/ai";
import { useTranslation } from "react-i18next";
// import Default from "../../pictures/default.jpg";
import useFetch from "../../hooks/useFetch";
import { BASE_URL } from "../../baseurl";
import Loading from "../loading";
import Arrowdown from "../../pictures/menu/arrowdown.svg";

const Uploadcategory = ({ language }) => {
  let URL = `${BASE_URL}/api/categories?populate=*`;
  if (language === "1") {
    URL = `${BASE_URL}/api/categories?locale=en&populate=*`;
  } else if (language === "2") {
    URL = `${BASE_URL}/api/categories?locale=ru&populate=*`;
  }
  let { data } = useFetch(URL);

  const isSubmitting = false;
  const [selectedTitle, setSelectedTitle] = useState("");
  const [selectedTitleEn, setSelectedTitleEn] = useState("");
  const [selectedTitleRu, setSelectedTitleRu] = useState("");
  const [sort, setSort] = useState();
  const [image, setImage] = useState(null);
  const { t } = useTranslation();
  const [fileName, setFileName] = useState("");
  const [photo, setPhoto] = useState(null);
  const [customLoad, setCustomLoad] = useState(false);
  const [isFoodOrBeverage, setIsFoodOrBeverage] = useState(false);

  function validateForm() {
    return (
      selectedTitle !== "" || selectedTitleEn !== "" || selectedTitleRu !== ""
    );
  }

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (selectedTitle === "") {
      // console.log("selectedTitleRu is empty. Skipping the request.");
      return;
    }

    setSelectedTitle("");
    setPhoto(null);
    setImage(null);
    setFileName(t("fileisnotchoosen"));
    setSort(null)
    let URL = `${BASE_URL}/api/categories`;

    const formData = new FormData();
    let data = {
      title: selectedTitle,
      alt: selectedTitle,
      sort:sort
    };

    setCustomLoad(true);

    formData.append("data", JSON.stringify(data));
    if (photo === null) {
      // const defaultPhoto = await fetch(Default);
      // const defaultPhotoBlob = await defaultPhoto.blob();
      // formData.append("files.img", defaultPhotoBlob, "default_photo.jpg");
    } else {
      formData.append("files.img", photo);
    }

    const response = await fetch(URL, {
      method: "POST",
      body: formData,
    });

    if (response.ok) {
      console.log("Category created successfully!");
      setSelectedTitle("");
      setCustomLoad(false);
    } else {
      console.error("Error creating category:", response.statusText);
      setCustomLoad(false);
    }
  };
  const handleSubmitEn = async (event) => {
    event.preventDefault();

    if (selectedTitleEn === "") {
      // console.log("selectedTitleRu is empty. Skipping the request.");
      return;
    }

    setSelectedTitleEn("");
    setPhoto(null);
    setImage(null);
    setFileName(t("fileisnotchoosen"));
    setSort(null)
    let URL = `${BASE_URL}/api/categories`;

    const formData = new FormData();
    data = {
      title: selectedTitleEn,
      alt: selectedTitleEn,
      sort: sort,
      locale: "en",
    };

    setCustomLoad(true);

    formData.append("data", JSON.stringify(data));
    if (photo === null) {
      // const defaultPhoto = await fetch(Default);
      // const defaultPhotoBlob = await defaultPhoto.blob();
      // formData.append("files.img", defaultPhotoBlob, "default_photo.jpg");
    } else {
      formData.append("files.img", photo);
    }

    const response = await fetch(URL, {
      method: "POST",
      body: formData,
    });

    if (response.ok) {
      console.log("Category created successfully!");
      setSelectedTitleEn("");
      setCustomLoad(false);
    } else {
      console.error("Error creating category:", response.statusText);
      setCustomLoad(false);
    }
  };
  const handleSubmitRu = async (event) => {
    event.preventDefault();

    if (selectedTitleRu === "") {
      // console.log("selectedTitleRu is empty. Skipping the request.");
      return;
    }

    setSelectedTitleRu("");
    setPhoto(null);
    setImage(null);
    setFileName(t("fileisnotchoosen"));
    setSort(null)
    let URL = `${BASE_URL}/api/categories`;

    const formData = new FormData();
    data = {
      title: selectedTitleRu,
      alt: selectedTitleRu,
      sort: sort,
      locale: "ru",
    };

    setCustomLoad(true);

    formData.append("data", JSON.stringify(data));
    if (photo === null) {
      // const defaultPhoto = await fetch(Default);
      // const defaultPhotoBlob = await defaultPhoto.blob();
      // formData.append("files.img", defaultPhotoBlob, "default_photo.jpg");
    } else {
      formData.append("files.img", photo);
    }

    const response = await fetch(URL, {
      method: "POST",
      body: formData,
    });

    if (response.ok) {
      console.log("Category created successfully!");
      setSelectedTitleRu("");
      setCustomLoad(false);
    } else {
      console.error("Error creating category:", response.statusText);
      setCustomLoad(false);
    }
  };

  return (
    <>
      {customLoad ? <Loading /> : null}
      <form
        className="form"
        onSubmit={(event) => {
          handleSubmit(event);
          handleSubmitEn(event);
          handleSubmitRu(event);
        }}
      >
        {/* Geo Feel */}

        <Row>
          <Col>
            <div>
              <label htmlFor="category">{t("categoryname")} GEO</label>
              <br />
              <div className="wrapper">
                <input
                  type="text"
                  value={selectedTitle}
                  onChange={(event) => setSelectedTitle(event.target.value)}
                />
              </div>
            </div>
          </Col>
        </Row>

        {/* English feel */}
        <Row style={{ marginTop: "30px" }}>
          <Col>
            <div>
              <label htmlFor="category">{t("categoryname")} ENG</label>
              <br />
              <div className="wrapper">
                <input
                  type="text"
                  value={selectedTitleEn}
                  onChange={(event) => setSelectedTitleEn(event.target.value)}
                />
              </div>
            </div>
          </Col>
        </Row>

        {/* Russian feel */}
        <Row style={{ marginTop: "30px" }}>
          <Col>
            <div>
              <label htmlFor="category">{t("categoryname")} RU</label>
              <br />
              <div className="wrapper">
                <input
                  type="text"
                  value={selectedTitleRu}
                  onChange={(event) => setSelectedTitleRu(event.target.value)}
                />
              </div>
            </div>
          </Col>
        </Row>

        {/* Food Or Beverage */}
        <Row style={{ marginTop: "30px" }}>
          <Col>
            <div>
              <label htmlFor="category">
                {t("foods")} / {t("beverage")}
              </label>
              <br />
              <div className="wrapper">
                <div className="select-btn" onClick={() => setIsFoodOrBeverage(!isFoodOrBeverage)}>
                <span>{sort === true ? t("foods") : sort === false ? t("beverage") : ""}</span>
                  <i
                    className={
                      isFoodOrBeverage ? "arrowdown-open" : "arrowdown"
                    }
                  >
                    <img src={Arrowdown} alt="arrow" />
                  </i>
                </div>
                <div
                  className={
                    isFoodOrBeverage ? "content show-dropdown" : "content"
                  }
                >
                  <ul className="options">
                    <li onClick={() => (setSort(true), setIsFoodOrBeverage(false))}>{t("foods")}</li>
                    <li onClick={() => (setSort(false), setIsFoodOrBeverage(false))}>{t("beverage")}</li>
                  </ul>
                </div>
              </div>
            </div>
          </Col>
        </Row>

        {/* Photo Uploader */}

        <Row className="margin-top-60">
          <Col
            className="uploaded"
            onClick={() => document.querySelector(".input-field").click()}
          >
            <input
              type="file"
              accept="image/*"
              className="input-field"
              hidden
              onChange={({ target: { files } }) => {
                if (files && files.length > 0) {
                  setFileName(files[0].name);
                  setImage(window.URL.createObjectURL(files[0]));
                  setPhoto(files[0]);
                }
              }}
            />

            {image ? (
              <div className="uploaded-img-wrapper border-top-upload">
                <img
                  className="border-radius-upload p-10"
                  src={image}
                  width={300}
                  alt={fileName}
                />
              </div>
            ) : (
              <>
                <div className="uploaded-img-wrapper border-top-upload">
                  <MdCloudUpload color="#7f6d47" size={60} />
                </div>
                <div className="uploaded-img-wrapper">
                  <p className="p-10">{t("upload")}</p>
                </div>
              </>
            )}
            <section className="uploaded-row">
              <AiFillFileImage color="#7f6d47" />
              <span className="upload-content">
                {fileName} -
                <MdDelete
                  color="#7f6d47"
                  onClick={() => {
                    setFileName(t("fileisnotchoosen"));
                    setImage(null);
                  }}
                />
              </span>
            </section>
          </Col>
        </Row>
        <Row className="margin-top-60 btn-wrapper-upload">
          <button
            disabled={!validateForm() || isSubmitting || sort == null}
            className="shadow-upload-button"
          >
            {t("submit")}
          </button>
        </Row>
      </form>
    </>
  );
};

export default Uploadcategory;
