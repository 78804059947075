import { useState, useEffect } from 'react';

function useFetch(url) {
  const [data, setData] = useState([]);
  const [daat, setDaat] = useState([])
  const [loading, setLoading] = useState(true);
  const [loadin, setLoadin] = useState(true);
  const [error, setError] = useState(null);

  const fetchData = async (url) => {
    setLoading(true);
    setLoadin(true);
    try {
      let result = await fetch(url);
      let response = await result.json();
      setData(response);
      setDaat(response)
    } catch (error) {
    //   console.log(error)
      setError(error);
      setLoading(false);
      setLoadin(false);
    } finally {
        setLoading(false);
        setLoadin(false);
    }
  }

  useEffect(() => {
    fetchData(url);
  }, [url]);

  return { data, loading, error, loadin, daat };
}

export default useFetch;