import { useState } from "react";
import ArrowUp from '../../pictures/menu/arrow.svg'

const Arrow = () => {
    const [fix, setFix] = useState(true);
    const setFixed = () => {
        if (window.scrollY >= 520) {
            setFix(true)
        } else {
            setFix(false)
        }
    }
    window.addEventListener('scroll', setFixed);
    const toTheCategory = () => {
        const element = document.querySelector(".scrollmenu");
        const targetTop = element.offsetTop - element.scrollHeight + 100;
        // const targetTop = element.offsetTop - element.scrollHeight + 240; OLD
        window.scrollTo({
          top: targetTop,
          left: 0,
          behavior: 'smooth'
        });
      }
  return (
    <div onClick={() => toTheCategory()} className={fix ? 'arrow opacity' : 'arrow'}>
        <img src={ArrowUp} alt="ArrowUp" />
    </div>
  )
}

export default Arrow