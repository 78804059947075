// import { useState } from 'react';
import { Row, Container, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom'
import { BASE_URL } from '../baseurl';
// import { foods } from '../data';
import { Cards } from '../components'
import useFetch from '../hooks/useFetch';
// {foods.map((item, key) =>
//     <Cards item={item} key={key} />
// )}
const FilteredCatalog = ({ wishlist, setWishlist, language }) => {
    let URL = `${BASE_URL}/api/foods?populate=*`;
        if (language === '1') {
             URL = `${BASE_URL}/api/foods?locale=en&populate=*`;
            } else if (language === '2') {
             URL = `${BASE_URL}/api/foods?locale=ru&populate=*`;
        }
    let { data } = useFetch(URL);
    const { t } = useTranslation();

    const { categoryId } = useParams();
    return (
        <>
        <main>
            <Container className='distinctive'>
                <Row>
                    <Col>
                        <p>{categoryId === "beverage" ? `${t('beverage')} /` : `${t('foods')} /`} <span>{t('all')}</span></p>
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row className='pb-100' xs={2} md={2} lg={3} xl={4} xxl={4}>
                    {data.data?.filter((e) => e.attributes.sort === categoryId).map((item, key) =>
                        <Cards item={item} key={key} setWishlist={setWishlist} wishlist={wishlist} />
                    )}
                </Row>
            </Container>
            </main>
        </>
    )
}

export default FilteredCatalog