import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { NavLink, useLocation } from "react-router-dom";
// import { categorydata } from "../../data";
import useFetch from '../../hooks/useFetch';
import Loading from "../loading/Loading";
import { BASE_URL } from "../../baseurl";

const Catalog = ({language, setCatalogId, loaderHandler}) => {
    let URL = `${BASE_URL}/api/categories?populate=*`;
        if (language === '1') {
             URL = `${BASE_URL}/api/categories?locale=en&populate=*`;
            } else if (language === '2') {
             URL = `${BASE_URL}/api/categories?locale=ru&populate=*`;
        }
    //  console.log(window.location.href.includes('/product/foods'))  
     
     if(window.location.href.includes('/product/foods')){
        URL = `${BASE_URL}/api/categories?filters[sort][$eq]=true&populate=*`;
        if (language === '1') {
             URL = `${BASE_URL}/api/categories?locale=en&filters[sort][$eq]=true&populate=*`;
            } else if (language === '2') {
             URL = `${BASE_URL}/api/categories?locale=ru&filters[sort][$eq]=true&populate=*`;
        }
     }
     if(window.location.href.includes('/product/beverage')){
        URL = `${BASE_URL}/api/categories?filters[sort][$eq]=false&populate=*`;
        if (language === '1') {
             URL = `${BASE_URL}/api/categories?locale=en&filters[sort][$eq]=false&populate=*`;
            } else if (language === '2') {
             URL = `${BASE_URL}/api/categories?locale=ru&filters[sort][$eq]=false&populate=*`;
        }
     }

    let { data, loading, error } = useFetch(URL);
    // console.log(data.data)
    // const [load, setLoad] = useState(true)

    // setTimeout(() => {
    //     setLoad(false);
    // }, 1000)

    // console.log(data, "Test")

    const location = useLocation();
    useEffect(() => {
    }, [location]);

     const [activeItemId, setActiveItemId] = useState(null);

  const handleItemClick = (itemId) => {
    // setLoad(true); // Assuming setLoad is a function that updates some state
    setCatalogId(itemId); // Assuming setCatalogId is a function that updates some state
    setActiveItemId(itemId);
  };

  const dataaaa = useFetch(`${BASE_URL}/api/logos?populate=*`);
  const logo = dataaaa?.data?.data;
  const img = logo?.map((e) => e.attributes?.img?.data?.attributes?.url);

    
    return (
        <div className="scrollcontainer">
            {loading && <Loading />}
            <Container style={{position:'relative'}} fluid>
                <div className="scrollmenu">
                    {data?.data?.map((item, key) => {
                        // if ((item.attributes.sort && window.location.pathname.includes('/product/foods')) || ((!item.attributes.sort && window.location.pathname.includes('/product/beverage')) || (window.location.pathname === '/'))) {
                            return (
                                <div attr={item.id} key={item.id}>
                                <NavLink className={activeItemId === item.id ? "activee" : ''}
                                  onClick={() => handleItemClick(item.id)}
                                  style={({ isActive }) => ({
                                    scale: isActive && activeItemId === item.id ? '1.2' : '1',
                                  })}
                                  to={item.attributes.sort === true ? "/product/foods" : "/product/beverage"}
                                >
                                  <img
                                  onClick={() => loaderHandler()}
                                    className="circle"
                                    src={`${BASE_URL}${item?.attributes?.img?.data?.attributes?.url == null ? img : item?.attributes?.img?.data?.attributes?.url}`}
                                    alt={item?.attributes?.alt}
                                  />
                                  <p>{item?.attributes?.title}</p>
                                </NavLink>
                              </div>
                            );
                        // }
                        // return null
                    })}
                </div>
                    <div style={{position:'absolute', color:'#7f6d47', fontSize:"18px", left:'0', bottom:'-9px'}}>◄</div>
                    <div style={{position:'absolute', color:'#7f6d47', fontSize:"18px", right:'0', bottom:'-9px'}} >►</div>
            </Container >
        </div >
    )
}

export default Catalog