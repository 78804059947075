import { Container } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';
import { BASE_URL } from "../../baseurl";
import useFetch from '../../hooks/useFetch';

const Wishlist = ({ wishlist, setWishlist, cartAppear, setCartAppear }) => {

    const data = useFetch(`${BASE_URL}/api/percentages`)

    let uniqueTitles = new Set();
    let inTotal = 0;
    let titleCounts = {}

    if(wishlist.length === 0) {
        setCartAppear(false);
    }

    const onDelete = itemToDelete => {
        const updatedWishlist = wishlist.filter(item => item !== itemToDelete);
        setWishlist(updatedWishlist);
    };
    wishlist.forEach(item => {
        inTotal += item?.attributes?.price;
        titleCounts[item?.attributes?.price] = (titleCounts[item?.attributes?.price] || 0) + 1;
        titleCounts[item?.attributes?.title] = (titleCounts[item?.attributes?.title] || 0) + 1;
    });

    const {t} = useTranslation();
    const dataaaa = useFetch(`${BASE_URL}/api/logos?populate=*`);
    const logo = dataaaa?.data?.data;
    const img = logo?.map((e) => e?.attributes?.img?.data?.attributes?.url);
    return (
        <>
            <Container className={cartAppear ? 'wishlist' : 'wishlist show'} fluid>
                <div className='horizontal-container'>
                    {wishlist.map((item, key) => {
                        if (uniqueTitles.has(item?.attributes?.title) && uniqueTitles.has(item?.attributes.price)) {
                            return null;
                        }
                        uniqueTitles.add(item?.attributes?.title);
                        uniqueTitles.add(item?.attributes?.price);
                        return (
                            <div key={key} className='inline-block'>
                                <div className="card height-for-wishlist">
                                    <img src={`${BASE_URL}${item?.attributes?.img?.data?.attributes?.url == null ? img : item?.attributes?.img?.data?.attributes?.url}`} alt="Avatar" />
                                    <div className="container">
                                       
                                            <p className='foodcount'>{titleCounts[item.attributes.price] || titleCounts[item.attributes.title]}</p>
                                        
                                        <div className='space-between'>
                                            <p>{item.attributes.title}</p>
                                            <p>{item.attributes.price}{t('currency')}</p>
                                        </div>
                                        {/* <p className="mt-12 mb-24 weight-500">{item.attributes.description}</p> */}
                                        <button className='addBtn' onClick={() => onDelete(item)}>{t('deletefromcart')}</button>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
                <h1>{t('tax')} {data.data?.data?.map((e => e.attributes.perc))}% - {(inTotal *= `1.${data.data?.data?.map((e => e.attributes.perc)) <= 9 ? `0${data.data?.data?.map((e => e.attributes.perc))}` : `${data.data?.data?.map((e => e.attributes.perc))}`}`).toFixed(2)}{t('currency')}</h1>
                {/* <h1>{t('tax')} {inTotal}{t('currency')}</h1> */}
            </Container>
        </>
    );
};

export default Wishlist;