import { useState } from "react"
import { Uploadnavbar, Loading } from "../components"


const Upload = ({setLanguage, language}) => {
  const [loading, setLoading] = useState(false)

  return (
    <>
      {/* <Loading loading={loading} /> */}
      <Uploadnavbar language={language} setLanguage={setLanguage} loading={loading} setLoading={setLoading} />
    </>
  )
}

export default Upload