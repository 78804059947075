import { Footer, Navbar, Wishlist, Arrow } from "../components";
import {
  FilteredProducts,
  Main,
  FilteredCatalog,
  Login,
  Upload,
} from "../pages";
import { Route, Routes, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import "../style/app.scss";

function App() {
  const [wishlist, setWishlist] = useState([]);
  const [cartAppear, setCartAppear] = useState(false);
  const { pathname } = useLocation();
  const [language, setLanguage] = useState("0");
  const [load, setLoad] = useState('true');
  const [catalogId, setCatalogId] = useState();
  // console.log(catalogId)

  useEffect(() => {
    document.body.classList.toggle("georgian", language === "0");
    document.body.classList.toggle("english", language === "1" || language === '2');
    const h1Elements = document.querySelectorAll("h1");
    for (let i = 0; i < h1Elements.length; i++) {
      h1Elements[i].classList.toggle("english", language === "2" || language ==='1');
    }
    const pElements = document.querySelectorAll("p");
    for (let i = 0; i < pElements.length; i++) {
      pElements[i].classList.toggle("english", language === "2" || language ==='1');
    }
    const spanElements = document.querySelectorAll("span");
    for (let i = 0; i < spanElements.length; i++) {
      spanElements[i].classList.toggle("english", language === "2" || language ==='1');
    }
    const buttonElement = document.querySelectorAll("button");
    for (let i = 0; i < buttonElement.length; i++) {
      buttonElement[i].classList.toggle("english", language === "2" || language ==='1');
    }
    const labelElement = document.querySelectorAll("label");
    for (let i = 0; i < labelElement.length; i++) {
      labelElement[i].classList.toggle("english", language === "2" || language ==='1');
    }
    const divElement = document.querySelectorAll("div");
    for (let i = 0; i < divElement.length; i++) {
      divElement[i].classList.toggle("english", language === "2" || language ==='1');
    }
  }, [language]);

  useEffect(() => {
    if (
      pathname.includes("/product/foods/") ||
      pathname.includes("/product/beverage/")
    ) {
      window.scrollTo({
        // top: 765,
        top:0,
        left: 0,
        behavior: "smooth",
      });
    } else {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }
  }, [pathname]);
  return (
    <>
      {window.location.pathname !== "/login/" &&
        window.location.pathname !== "/upload/" &&
        window.location.pathname !== "/login" &&
        window.location.pathname !== "/upload" && (
          <Navbar
          setCatalogId={setCatalogId}
            load={load}
            setLoad={setLoad}
            wishlist={wishlist}
            cartAppear={cartAppear}
            language={language}
            setLanguage={setLanguage}
            setCartAppear={setCartAppear}
          />
        )}
      <Routes>
        <Route
          path="/"
          element={
            <Main
              catalogId={catalogId}
              setWishlist={setWishlist}
              load={load}
              setLoad={setLoad}
              language={language}
              wishlist={wishlist}
            />
          }
        />
        <Route
          path="/product/foods"
          element={
            <Main
              catalogId={catalogId}
              setWishlist={setWishlist}
              load={load}
              setLoad={setLoad}
              language={language}
              wishlist={wishlist}
            />
          }
        />
        <Route
          path="/product/beverage"
          element={
            <Main
              catalogId={catalogId}
              setWishlist={setWishlist}
              load={load}
              setLoad={setLoad}
              language={language}
              wishlist={wishlist}
            />
          }
        />
        <Route
          path="/product/:categoryId"
          element={
            <FilteredCatalog
              language={language}
              setWishlist={setWishlist}
              wishlist={wishlist}
            />
          }
        />
        <Route
          path="/product/:categoryId/:productId"
          element={
            <FilteredProducts
              language={language}
              setWishlist={setWishlist}
              wishlist={wishlist}
              catalogId={catalogId}
            />
          }
        />
        <Route path="/login" element={<Login />} />
        <Route
          path="/upload"
          element={<Upload language={language} setLanguage={setLanguage} />}
        />
      </Routes>
      <Wishlist
        wishlist={wishlist}
        setWishlist={setWishlist}
        cartAppear={cartAppear}
        setCartAppear={setCartAppear}
      />
      {window.location.pathname !== "/login/" &&
        window.location.pathname !== "/upload/" &&
        window.location.pathname !== "/login" &&
        window.location.pathname !== "/upload" && <Arrow />}
      {window.location.pathname !== "/login/" &&
        window.location.pathname !== "/upload/" &&
        window.location.pathname !== "/login" &&
        window.location.pathname !== "/upload" && <Footer language={language} />}
    </>
  );
}

export default App;
