import { useTranslation } from "react-i18next";
import { Row, Col } from "react-bootstrap";
import { useEffect, useState } from "react";
import { BASE_URL } from "../../baseurl";
import Loading from "../loading";
import axios from "axios";

const Content = () => {
  const { t } = useTranslation();
  const initialState = {
    mobilenumb: "",
    workhours: "",
    fblink: "",
    iglink: "",
    restnameone: "",
    restnametwo: "",
    address: "",
  };
  const [sendData, setSendData] = useState(initialState);
  const [sendDataEn, setSendDataEn] = useState(initialState);
  const [sendDataRu, setSendDataRu] = useState(initialState);
  const [loading, setLoading] = useState(false);
  let {
    mobilenumb,
    workhours,
    fblink,
    iglink,
    restnameone,
    restnametwo,
    address,
  } = sendData;
  useEffect(() => {
    console.table({
      mobilenumb,
      workhours,
      fblink,
      iglink,
      restnameone,
      restnametwo,
      address,
    });
  }, [
    mobilenumb,
    workhours,
    fblink,
    iglink,
    restnameone,
    restnametwo,
    address,
  ]);
  const handleUpdate = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    const formDataEn = new FormData();
    const formDataRu = new FormData();
    let data = { ...sendData };
    let dataEn = { ...sendDataEn, locale: "en" };
    let dataRu = { ...sendDataRu, locale: "ru" };
    data = removeEmptyKeys(data);
    dataEn = removeEmptyKeys(dataEn);
    dataRu = removeEmptyKeys(dataRu);
    formData.append("data", JSON.stringify(data));
    formDataEn.append("data", JSON.stringify(dataEn));
    formDataRu.append("data", JSON.stringify(dataRu));
    const geoId = dataGe?.data?.map((el) => el?.id);
    const EnId = dataEnglish?.data?.map((el) => el?.id);
    const RuId = dataRusian?.data?.map((el) => el?.id);
    console.log(EnId)
    setLoading(true);
    try {
      const response = await axios.put(
        `${BASE_URL}/api/footercontents/${geoId}`,
        formData
      );
      const responseEn = await axios.put(
        `${BASE_URL}/api/footercontents/${EnId}?locale=en`,
        formDataEn
      );
      const responseRu = await axios.put(
        `${BASE_URL}/api/footercontents/${RuId}?locale=ru`,
        formDataRu
      );
      console.log(
        "Update successful",
        response.data,
        responseEn.data,
        responseRu.data,
        setLoading(false),
        dataGeo()
      );
    } catch (error) {
      console.error("Error updating data", error);
      setLoading(false)
      dataGeo()
    }
  };

  const removeEmptyKeys = (obj) => {
    const newObj = {};
    for (const key in obj) {
        if (obj[key] !== "") {
            newObj[key] = obj[key];
        }
    }
    return newObj;
};

  const [dataGe, setDataGe] = useState([]);
  const [dataEnglish, setDataEn] = useState([]);
  const [dataRusian, setDataRu] = useState([]);
  const dataGeo = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/api/footercontents`);
      const responseEn = await axios.get(`${BASE_URL}/api/footercontents?locale=en`);
      const responseRu = await axios.get(`${BASE_URL}/api/footercontents?locale=ru`);
      setDataGe(response?.data);
      setDataEn(responseEn?.data);
      setDataRu(responseRu?.data);
      console.log("Get Successfuly");
      setSendData(initialState)
      setSendDataEn(initialState)
      setSendDataRu(initialState)
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      setSendData(initialState)
      setSendDataEn(initialState)
      setSendDataRu(initialState)
    }
  };

  useEffect(() => {
    dataGeo();
  }, []);

  return (
    <>
      {loading ? <Loading /> : ""}
      <form className="form" onSubmit={(event) => handleUpdate(event)}>
        <label style={{ fontSize: "22px", fontWeight: "600" }}>ქართული</label>
        <Row style={{ marginTop: "30px" }}>
          <Col>
            <div>
              <label htmlFor="category">{t("Mobilenumb")}</label>
              <br />
              <div className="wrapper">
                <input
                  placeholder={dataGe?.data?.map(
                    (el) => el?.attributes?.mobilenumb
                  )}
                  type="text"
                  value={sendData.mobilenumb}
                  onChange={(event) => (
                    setSendData((prevState) => ({
                      ...prevState,
                      mobilenumb: event.target.value,
                    })),
                    setSendDataEn((prevState) => ({
                      ...prevState,
                      mobilenumb: event.target.value,
                    })),
                    setSendDataRu((prevState) => ({
                      ...prevState,
                      mobilenumb: event.target.value,
                    }))
                  )}
                />
              </div>
            </div>
          </Col>
          <Col>
            <div>
              <label htmlFor="category">{t("Workhours")}</label>
              <br />
              <div className="wrapper">
                <input
                value={sendData.workhours}
                  type="text"
                  placeholder={dataGe?.data?.map(
                    (el) => el?.attributes?.workhours
                  )}
                  onChange={(event) => (
                    setSendData((prevState) => ({
                      ...prevState,
                      workhours: event.target.value,
                    })),
                    setSendDataEn((prevState) => ({
                      ...prevState,
                      workhours: event.target.value,
                    })),
                    setSendDataRu((prevState) => ({
                      ...prevState,
                      workhours: event.target.value,
                    }))
                  )}
                />
              </div>
            </div>
          </Col>
        </Row>
        <Row style={{ marginTop: "30px" }}>
          <Col>
            <div>
              <label htmlFor="category">{t("fb")}</label>
              <br />
              <div className="wrapper">
                <input
                value={sendData.fblink}
                 placeholder={dataGe?.data?.map(
                    (el) => el?.attributes?.fblink
                  )}
                  type="text"
                  onChange={(event) => (
                    setSendData((prevState) => ({
                      ...prevState,
                      fblink: event.target.value,
                    })),
                    setSendDataEn((prevState) => ({
                      ...prevState,
                      fblink: event.target.value,
                    })),
                    setSendDataRu((prevState) => ({
                      ...prevState,
                      fblink: event.target.value,
                    }))
                  )}
                />
              </div>
            </div>
          </Col>
          <Col>
            <div>
              <label htmlFor="category">{t("inst")}</label>
              <br />
              <div className="wrapper">
                <input
                value={sendData.iglink}
                  type="text"
                  placeholder={dataGe?.data?.map(
                    (el) => el?.attributes?.iglink
                  )}
                  onChange={(event) => (
                    setSendData((prevState) => ({
                      ...prevState,
                      iglink: event.target.value,
                    })),
                    setSendDataEn((prevState) => ({
                      ...prevState,
                      iglink: event.target.value,
                    })),
                    setSendDataRu((prevState) => ({
                      ...prevState,
                      iglink: event.target.value,
                    }))
                  )}
                />
              </div>
            </div>
          </Col>
        </Row>
        <Row style={{ marginTop: "30px", marginBottom: "30px" }}>
          <Col>
            <div>
              <label htmlFor="category">{t("firstline")}</label>
              <br />
              <div className="wrapper">
                <input
                value={sendData.restnameone}
                  type="text"
                  placeholder={dataGe?.data?.map(
                    (el) => el?.attributes?.restnameone
                  )}
                  onChange={(event) =>
                    setSendData((prevState) => ({
                      ...prevState,
                      restnameone: event.target.value,
                    }))
                  }
                />
              </div>
            </div>
          </Col>
          <Col>
            <div>
              <label htmlFor="category">{t("secondline")}</label>
              <br />
              <div className="wrapper">
                <input
                value={sendData.restnametwo}
                  type="text"
                  placeholder={dataGe?.data?.map(
                    (el) => el?.attributes?.restnametwo
                  )}
                  onChange={(event) =>
                    setSendData((prevState) => ({
                      ...prevState,
                      restnametwo: event.target.value,
                    }))
                  }
                />
              </div>
            </div>
          </Col>
          <Col>
            <div>
              <label htmlFor="category">{t("address")}</label>
              <br />
              <div className="wrapper">
                <input
                value={sendData.address}
                  type="text"
                  placeholder={dataGe?.data?.map(
                    (el) => el?.attributes?.address
                  )}
                  onChange={(event) =>
                    setSendData((prevState) => ({
                      ...prevState,
                      address: event.target.value,
                    }))
                  }
                />
              </div>
            </div>
          </Col>
        </Row>

        {/* English */}
        <label style={{ fontSize: "22px", fontWeight: "600" }}>English</label>
        <Row style={{ marginTop: "30px", marginBottom: "30px" }}>
          <Col>
            <div>
              <label htmlFor="category">{t("firstline")}</label>
              <br />
              <div className="wrapper">
                <input
                value={sendDataEn.restnameone}
                  type="text"
                  placeholder={dataEnglish?.data?.map(
                    (el) => el?.attributes?.restnameone
                  )}
                  onChange={(event) =>
                    setSendDataEn((prevState) => ({
                      ...prevState,
                      restnameone: event.target.value,
                    }))
                  }
                />
              </div>
            </div>
          </Col>
          <Col>
            <div>
              <label htmlFor="category">{t("secondline")}</label>
              <br />
              <div className="wrapper">
                <input
                  type="text"
                  value={sendDataEn.restnametwo}
                  placeholder={dataEnglish?.data?.map(
                    (el) => el?.attributes?.restnametwo
                  )}
                  onChange={(event) =>
                    setSendDataEn((prevState) => ({
                      ...prevState,
                      restnametwo: event.target.value,
                    }))
                  }
                />
              </div>
            </div>
          </Col>
          <Col>
            <div>
              <label htmlFor="category">{t("address")}</label>
              <br />
              <div className="wrapper">
                <input
                value={sendDataEn.address}
                  type="text"
                  placeholder={dataEnglish?.data?.map(
                    (el) => el?.attributes?.address
                  )}
                  onChange={(event) =>
                    setSendDataEn((prevState) => ({
                      ...prevState,
                      address: event.target.value,
                    }))
                  }
                />
              </div>
            </div>
          </Col>
        </Row>

        {/* Russian */}
        <label style={{ fontSize: "22px", fontWeight: "600" }}>Russian</label>
        <Row style={{ marginTop: "30px", marginBottom: "30px" }}>
          <Col>
            <div>
              <label htmlFor="category">{t("firstline")}</label>
              <br />
              <div className="wrapper">
                <input
                  type="text"
                  value={sendDataRu.restnameone}
                  placeholder={dataRusian?.data?.map(
                    (el) => el?.attributes?.restnameone
                  )}
                  onChange={(event) =>
                    setSendDataRu((prevState) => ({
                      ...prevState,
                      restnameone: event.target.value,
                    }))
                  }
                />
              </div>
            </div>
          </Col>
          <Col>
            <div>
              <label htmlFor="category">{t("secondline")}</label>
              <br />
              <div className="wrapper">
                <input
                  type="text"
                  value={sendDataRu.restnametwo}
                  placeholder={dataRusian?.data?.map(
                    (el) => el?.attributes?.restnametwo
                  )}
                  onChange={(event) =>
                    setSendDataRu((prevState) => ({
                      ...prevState,
                      restnametwo: event.target.value,
                    }))
                  }
                />
              </div>
            </div>
          </Col>
          <Col>
            <div>
              <label htmlFor="category">{t("address")}</label>
              <br />
              <div className="wrapper">
                <input
                  type="text"
                  value={sendDataRu.address}
                  placeholder={dataRusian?.data?.map(
                    (el) => el?.attributes?.address
                  )}
                  onChange={(event) =>
                    setSendDataRu((prevState) => ({
                      ...prevState,
                      address: event.target.value,
                    }))
                  }
                />
              </div>
            </div>
          </Col>
        </Row>

        <Row className="margin-top-60 btn-wrapper-upload">
          <button
            // disabled={!validateForm() || isSubmitting || sort == null}
            className="shadow-upload-button"
          >
            {t("submit")}
          </button>
        </Row>
      </form>
    </>
  );
};

export default Content;
