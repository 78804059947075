import React, { useEffect, useState } from "react";
import useFetch from "../../hooks/useFetch";
import { Row, Col } from "react-bootstrap";
import { MdDelete } from "react-icons/md";
import { useTranslation } from "react-i18next";
import Loading from "../loading/Loading";
import { BASE_URL } from "../../baseurl";
import Arrowdown from "../../pictures/menu/arrowdown.svg";

const Foodredact = ({ setLoading, language, }) => {
  const [load, setLoad] = useState(false);
  const [data, setData] = useState();
  const [categiryData, setCategoryData] = useState();
  const [selectCategoryId, setSelectCategoryId] = useState("");
  let URLS = `${BASE_URL}/api/foods?populate=*`;
  if (language === '1') {
    URLS = `${BASE_URL}/api/foods?locale=en&populate=*`;
  } else if (language === '2') {
    URLS = `${BASE_URL}/api/foods?locale=ru&populate=*`;
  }
  let CATEGORY_URL = `${BASE_URL}/api/categories?populate=*`;
  if (language === '1') {
    CATEGORY_URL = `${BASE_URL}/api/categories?locale=en&populate=*`;
  } else if (language === '2') {
    CATEGORY_URL = `${BASE_URL}/api/categories?locale=ru&populate=*`;
  }

  // console.log(selectCategoryId)

  useEffect(() => {
    

    setLoad(true)

    fetch(URLS)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setData(data);
        setLoad(false)
      })
      .catch((error) => {
        console.error("There was a problem fetching the data:", error);
        setLoad(false)
      });

    fetch(CATEGORY_URL)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setCategoryData(data);
        setLoad(false)
      })
      .catch((error) => {
        console.error("There was a problem fetching the data:", error);
        setLoad(false)
      });
  }, [language]);


  const reRender = () => {
    setLoad(true)

    fetch(URLS)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setData(data);
        setLoad(false)
      })
      .catch((error) => {
        console.error("There was a problem fetching the data:", error);
        setLoad(false)
      });
  }



  // let { data, loading, error } = useFetch(URLS);
  let [showInputs, setShowInputs] = useState(false);
  const [fileName, setFileName] = useState("ფაილი არ არის არჩეული");
  const [image, setImage] = useState(null);
  const [photo, setPhoto] = useState(null);
  const [id, setId] = useState(Number);
  const [price, setPrice] = useState("");
  const [description, setDescription] = useState("");
  const [title, setTitle] = useState("");
  const [hide, setHide] = useState(false);
  const [anotherFeatureTitle, setAnotherFeatureTitle] = useState("");
  const [anotherFeatureTitleTwo, setAnotherFeatureTitleTwo] = useState("");
  const [anotherFeatureTitleThree, setAnotherFeatureTitleThree] = useState("");
  const [anotherFeaturePrice, setAnotherFeaturePrice] = useState("");
  const [anotherFeaturePriceTwo, setAnotherFeaturePriceTwo] = useState("");
  const [anotherFeaturePriceThree, setAnotherFeaturePriceThree] = useState("");
  const [DeleteAccept, setDeleteAccept] = useState(false)
  
  const {t} = useTranslation();
  const deleteHandler = () => {
    setLoad(true);
    fetch(`${BASE_URL}/api/foods/${id}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json'
    },
  })
  .then(response => {
    if (!response.ok) {
      throw new Error('Network response was not ok');
      
    }
    return response.json();
  })
  .then(dataa => {
    setLoading(false);
    console.log('Item deleted:', dataa);
    data = dataa
    reRender();
    setLoad(false);
  })
  .catch(error => {
    console.error('There was a problem deleting the item:', error);
    reRender();
    setLoad(false);
  });
  }

  

  const [checkedValue, setCheckedValue] = useState(null);
  const redactHandler = (id) => {
    setShowInputs(true);
    setId(id);
  };

  // console.log(photo)

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    let data = {
      title: title,
      price: price,
      hide:hide,
      categorys:selectCategoryId,
      description: description,
      small:anotherFeatureTitle,
      medium:anotherFeatureTitleTwo,
      big:anotherFeatureTitleThree,
      smallprice:anotherFeaturePrice,
      mediumprice:anotherFeaturePriceTwo,
      bigprice:anotherFeaturePriceThree
      // anotherfeature: [
      //   {
      //     __component: "select.anotherfeature",
      //     title: anotherFeatureTitle,
      //     price: anotherFeaturePrice
      //   },
      //   {
      //     __component: "select.anotherfeatiretwo",
      //     title: anotherFeatureTitleTwo,
      //     price: anotherFeaturePriceTwo
      //   },
      //   {
      //     __component: "select.anotherfeatirethree",
      //     title: anotherFeatureTitleThree,
      //     price: anotherFeaturePriceThree
      //   }
      // ]
    };


    setLoad(true);

    // setTimeout(() => {
      setShowInputs(false);
    // }, 1000 )

    setId(null)
    setImage(null)

    if (data.title === '') {
      delete data.title;
    }

    if(data.categorys === ''){
      delete data.categorys;
    }
    
    if (data.price === '') {
      delete data.price;
    }
    
    if (data.description === '') {
      delete data.description;
    }
    if(data.big === ""){
      delete data.big
    }
    if(data.small === ""){
      delete data.small
    }
    if(data.medium === ""){
      delete data.medium
    }
    if(data.mediumprice === ""){
      delete data.mediumprice
    }
    if(data.bigprice === ""){
      delete data.bigprice
    }
    if(data.smallprice === ""){
      delete data.smallprice
    }

    // data.anotherfeature = data.anotherfeature.filter((feature) => {
    //   if (feature.title === '' && feature.price === '') {
    //     return false;
    //   }
    
    //   if (feature.title === '') {
    //     delete feature.title;
    //   }
    
    //   if (feature.price === '') {
    //     delete feature.price;
    //   }
    
    //   return true;
    // });

      formData.append("data", JSON.stringify(data));
      formData.append("files.img", photo);
    

      setAnotherFeatureTitle("")
      setAnotherFeatureTitleTwo("")
      setAnotherFeatureTitleThree("")
      setAnotherFeaturePrice("")
      setAnotherFeaturePriceTwo("")
      setAnotherFeaturePriceThree("")
      setPrice("")
      setTitle("")
      setDescription("")
      setSelectCategoryId("")
      

    const response = await fetch(`${BASE_URL}/api/foods/${id}?populate=*`, {
      method: "PUT",
      body: formData,
    });
    if (response.ok) {
      console.log("Category created successfully!");
      setLoad(false);
      setImage(null);
      setPhoto(null);
      reRender();
    } else {
      console.error("Error creating category:", response.statusText);
      setLoad(false);
      reRender();
    }
  };

  const checkedUncheckedHandler = (e) => {
    const checkboxes = document.querySelectorAll(
      '.checkbox-container input[type="checkbox"]'
    );
    checkboxes.forEach((checkbox) => {
      if (checkbox !== e.target && checkbox.checked) {
        checkbox.checked = false;
      } else if (checkbox === e.target && checkbox.checked) {
        setCheckedValue(Number(checkbox.value));
      }
    });
  };

  const [searchTitle, setSearchTitle] = useState('');

  const searchHandler = (title) => {
    setSearchTitle(title);
  };
  

  const dataa = useFetch(`${BASE_URL}/api/logos?populate=*`)
          const logo = dataa?.data?.data;
          const img  = logo?.map((e) => e?.attributes?.img?.data?.attributes?.url);

  return (
    <>
    {/* {loading && <Loading />} */}
    {load && <Loading />}
    {DeleteAccept && <div style={{position:"fixed", zIndex:"1000", height:"100vh", width:'100vw', background:'#383636ab', top:"50%", left:'50%', transform:'translate( -50%, -50%)'}}>
        <div style={{position:'fixed', zIndex:'100', border:'1px solid #7f6d47', borderRadius:'10px', background:'white',top:"50%", left:'50%', transform:'translate( -50%, -50%)' }}>
          <p style={{padding:'1.5rem'}}>გსურთ ნამდვილად პროდუქტის წაშლა?</p>
          <div style={{display:'flex', padding:'0 2rem', paddingTop:'0', paddingBottom:'1rem', justifyContent:'right'}}>
          <button onClick={() => (deleteHandler(), setDeleteAccept(false), setShowInputs(false))} style={{padding:"0.3rem 1rem", fontFamily:'GramatikaGeo', cursor:'pointer', border:'none', borderRadius:"4px", background:'#16ca16', color:'white', marginLeft:"10px"}}>დიახ</button>
          <button onClick={() => setDeleteAccept(false)} style={{padding:"0.3rem 1rem", fontFamily:'GramatikaGeo', cursor:'pointer', border:'none', borderRadius:"4px", background:'red', color:'white', marginLeft:"10px"}}>არა</button>
          </div>
        </div>
        </div>}
      <form style={{position:'relative'}} onSubmit={handleSubmit}>
      <Row className="search-wrapper">
      <input className="search"
        type="text"
        value={searchTitle}
        placeholder={t('search')}
        onChange={(e) => searchHandler(e.target.value)}
      />
      </Row>
        <Row className="pb-100" xs={1} md={2} lg={3} xl={4} xxl={4}>
          {data?.data?.map((item, key) => {
              const title = item.attributes.title ? item.attributes.title.toLowerCase() : '';
              const searchTerm = searchTitle.toLowerCase();
      
              const shouldHideCard = !title.includes(searchTerm);
              const cardClassNames = shouldHideCard ? 'none' : '';
            
              return(
            <Col style={{marginBottom:'30px'}} key={key} className={cardClassNames}>
              <div
                className={
                  showInputs && item.id === id
                    ? "card mb-24 hover-off card-redact-height" 
                    : "card mb-24 hover-off"
                }
              >
                {showInputs && item.id !== id && (
                  <img
                    src={`${BASE_URL}${item?.attributes?.img?.data?.attributes?.url == null ? img : item?.attributes?.img?.data?.attributes?.url}`}
                    alt="Avatar"
                  />
                )}
                {!showInputs && item.id !== id && (
                  <img
                    src={`${BASE_URL}${item?.attributes?.img?.data?.attributes?.url == null ? img : item?.attributes?.img?.data?.attributes?.url}`}
                    alt="Avatar"
                  />
                )}
                {showInputs && item.id === id && (
                  <Col
                    className="uploaded"
                    onClick={() =>
                      document.querySelector(".input-field").click()
                    }
                  >
                    <input
                      type="file"
                      accept="image/*"
                      className="input-field"
                      hidden
                      onChange={({ target: { files } }) => {
                        files[0] && setFileName(files[0].name);
                        if (files) {
                          setImage(URL.createObjectURL(files[0]));
                          setPhoto(files[0]);
                        }
                      }}
                    />

                    {image ? (
                      <div className="uploaded-img-wrapper border-top-upload">
                        <img
                          className="border-radius-upload p-10"
                          src={image}
                          width={300}
                          alt={fileName}
                        />
                      </div>
                      
                    ) : (
                      <>
                        <div className="uploaded-img-wrapper border-top-upload">
                          <img
                              src={`${BASE_URL}${item?.attributes?.img?.data?.attributes?.url == null ? img : item?.attributes?.img?.data?.attributes?.url}`}
                            alt="Avatar"
                          />
                        </div>
                      </>
                    )}
                  </Col>
                )}
                <div className="container">
                  <div className="space-between">
                    {showInputs && item.id !== id && (
                      <p className="title-value">{item.attributes.title}</p>
                    )}
                    {!showInputs && item.id !== id && (
                      <p>{item.attributes.title}</p>
                    )}
                    {showInputs && item.id === id && (
                      <input
                        className="titleinput inputforredact"
                        placeholder={item.attributes.title}
                        onChange={(event) => setTitle(event.target.value)}
                        type="text"
                      />
                    )}
                    {showInputs && item.id !== id && (
                      <p>
                        {checkedValue === null
                          ? item.attributes.price
                          : checkedValue}
                        {t('currency')}
                      </p>
                    )}
                    {!showInputs && item.id !== id && (
                      <p>
                        {checkedValue === null
                          ? item.attributes.price
                          : checkedValue}
                        {t('currency')}
                      </p>
                    )}
                    {showInputs && item.id === id && (
                      <input
                        className="priceinput inputforredact"
                        placeholder={item.attributes.price}
                        onChange={(event) => setPrice(event.target.value)}
                        type="number"
                        step="0.01"
                      />
                    )}
                  </div>
                  {showInputs && item.id !== id && (
                    <p className="mt-12 weight-500">{item.attributes.description}</p>
                  )}
                  <button className="addBtn" style={{ display: showInputs && item.id === id  ? '' : 'none', bottom:'37px', borderRadius:'0' }}  onClick={(event) => {
                    setDeleteAccept(true);
                    event.preventDefault();
                  }}>
                    {/* <span className="delete-text">{t('delete')}</span> */}
                  {/* <MdDelete  className="opac"
                  color="#7f6d47"
                  size="1.6rem"
                   /> */}
                   {t('delete')}
                  </button>
                  {!showInputs && item.id !== id && (
                    <p className="mt-12 weight-500">{item.attributes.description}</p>
                  )}
                  {showInputs && item.id === id && (
                    <input
                      className="mt-12 inputforredact width-100"
                      onChange={(event) => setDescription(event.target.value)}
                      // maxLength={110}
                      placeholder={item.attributes.description === null ? t('productdescription') : item.attributes.description}
                      type="text"
                    />
                  )}
                  {item.attributes && (
                    <div className="mt-12" style={{flexDirection:"column"}}>
                      {/* {item.attributes.anotherfeature.map((el, key) => ( */}
                        <div className="direction-column" key={key}>
                          <div className="checkbox-container">
                            {showInputs && item.id !== id &&  (
                              <input
                                id="cb01"
                                type="checkbox"
                                className="event-none"
                                value={item.attributes.small}
                                defaultChecked={key === 0}
                                onChange={checkedUncheckedHandler}
                              />
                            )}
                            {!showInputs && item.id !== id && item?.attributes?.smallprice !== null && (
                              <input
                                id="cb01"
                                type="checkbox"
                                className="event-none"
                                value={item.attributes.smallprice}
                                defaultChecked={key === 0}
                                onChange={checkedUncheckedHandler}
                              />
                            )}
                            {showInputs && item.id !== id && <label>{item.attributes.small}</label>}
                            {!showInputs && item.id !== id && <label>{item.attributes.small}</label>}
                          </div>
                        </div>

                        <div className="direction-column" key={key}>
                          <div className="checkbox-container">
                            {showInputs && item.id !== id && (
                              <input
                                id="cb01"
                                type="checkbox"
                                className="event-none"
                                value={item.attributes.medium}
                                defaultChecked={key === 0}
                                onChange={checkedUncheckedHandler}
                              />
                            )}
                            {!showInputs && item.id !== id && item?.attributes?.mediumprice !== null && (
                              <input
                                id="cb01"
                                type="checkbox"
                                className="event-none"
                                value={item.attributes.mediumprice}
                                defaultChecked={key === 0}
                                onChange={checkedUncheckedHandler}
                              />
                            )}
                            {showInputs && item.id !== id && <label>{item.attributes.medium}</label>}
                            {!showInputs && item.id !== id && <label>{item.attributes.medium}</label>}
                          </div>
                        </div>

                        <div className="direction-column" key={key}>
                          <div className="checkbox-container">
                            {showInputs && item.id !== id && (
                              <input
                                id="cb01"
                                type="checkbox"
                                className="event-none"
                                value={item.attributes.big}
                                defaultChecked={key === 0}
                                onChange={checkedUncheckedHandler}
                              />
                            )}
                            {!showInputs && item.id !== id && item?.attributes?.bigprice !== null && (
                              <input
                                id="cb01"
                                type="checkbox"
                                className="event-none"
                                value={item.attributes.bigprice}
                                defaultChecked={key === 0}
                                onChange={checkedUncheckedHandler}
                              />
                            )}
                            {showInputs && item.id !== id && <label>{item.attributes.big}</label>}
                            {!showInputs && item.id !== id && <label>{item.attributes.big}</label>}
                          </div>
                        </div>


                      {/* // ))} */}
                    </div>
                  )}
                  <div className="direction-column mt-24">
                  <div className="direction-column">
                    {showInputs && item.id === id && (
                      <div>
                      <input
                        onChange={(event) => setAnotherFeatureTitle(event.target.value)}
                        className="mt-12 inputforredact width-100"
                        placeholder={item?.attributes?.small === "" ? t('tobefilled') : item?.attributes?.small}
                        // value={item?.attributes?.anotherfeature[0]?.title}
                        type="text"
                      />
                      <input
                      onChange={(event) => setAnotherFeatureTitleTwo(event.target.value)}
                        className="mt-12 inputforredact width-100"
                        placeholder={item?.attributes?.medium === "" ? t('tobefilled') : item?.attributes?.medium}
                        // value={item?.attributes?.anotherfeature[1]?.title}
                        type="text"
                      />
                      <input
                      onChange={(event) => ((setAnotherFeatureTitleThree(event.target.value)))}
                        className="mt-12 inputforredact width-100"
                        placeholder={item?.attributes?.big === "" ? t('tobefilled') : item?.attributes?.big}
                        // value={item?.attributes?.anotherfeature[2]?.title}
                        type="text"
                      />
                      </div>
                    )}
                  </div>
                  </div>
                  {/* <div className="direction-column"> */}
                  <div className="direction-column">
                    {showInputs && item.id === id && (
                      <div>
                      <input
                      onChange={(event) => setAnotherFeaturePrice(event.target.value)}
                        className="mt-12 inputforredact width-100"
                        placeholder={item?.attributes?.smallprice === null ? t('tobefilled') : item?.attributes?.smallprice}
                        // value={item?.attributes?.anotherfeature[0]?.price}
                        type="number" step="0.01"
                      />
                      <input
                      onChange={(event) => setAnotherFeaturePriceTwo(event.target.value)}
                        className="mt-12 inputforredact width-100"
                        placeholder={item?.attributes?.mediumprice === null ? t('tobefilled') : item?.attributes?.mediumprice}
                        // value={item?.attributes?.anotherfeature[1]?.price}
                        type="number" step="0.01"
                      />
                      <input
                      onChange={(event) => setAnotherFeaturePriceThree(event.target.value)}
                        className="mt-12 inputforredact width-100"
                        placeholder={item?.attributes?.bigprice === null ? t('tobefilled') : item?.attributes?.bigprice}
                        // value={item?.attributes?.anotherfeature[2]?.price}
                        type="number" step="0.01"
                      />
                      </div>
                    )}
                  {/* </div> */}
                  </div>
                  {!showInputs && (
                    <div
                      onClick={() => redactHandler(item.id)}
                      className="addBtn bt"
                    >
                      {t('redact')}
                    </div>
                  )}
                  {showInputs && item.id !== id && (
                    <div
                      onClick={() => redactHandler(item.id)}
                      className="addBtn bt event-none"
                    >
                      {t('redact')}
                    </div>
                  )}
                  {showInputs && item.id === id && (
                   <>
                    <button className="addBtn" >{t('save')}</button>
                    <select onChange={(e) => setHide(e.target.value)} style={{width:'100%', cursor:'pointer', margin:'54px 0',  padding:'0.4rem', fontWeight:"800", position:"absolute", left:"0", border:'none', background:"#f7f0e8", fontFamily:'GramatikaGeo'}}>
                      <option style={{display:'none'}}>{t("status")} : {item.attributes.hide === false ? t('show') : t('hideden')}</option>
                        <option value="false">{t('show')}</option>
                        <option value="true">{t('hideden')}</option>
                    </select>
                    {/* <button className="addBtn" style={{bottom:'36px', borderRadius:'0'}}>{t('status')}: {item.attributes.hide === false ? t('show') : t('hideden')}</button> */}
                    {/* <button className="addBtn" style={{bottom:'36px', borderRadius:'0'}} onClick={(event) => (event.preventDefault(), setHide(!hide))} >{hide === item?.attributes?.hide ? "დამალვა" : "გამოჩენა"}</button> */}
                   </>
                  )}
                  {showInputs && item.id === id && (
                   <div style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                    <select onChange={(e) => setSelectCategoryId(e.target.value)} style={{width:'100%', cursor:'pointer', margin:'10px 0', borderRadius:'20px', padding:'0.4rem', border:'1px solid #f7f0e8', fontFamily:'GramatikaGeo'}}>
                      <option style={{display:'none'}}>{item?.attributes?.categorys?.data?.attributes?.title}</option>
                      {categiryData?.data?.map((e) => (
                        <option value={e.id}>{e.attributes.title}</option>
                      ))}
                    </select>
                   </div>
                  )}
                </div>
              </div>
            </Col>
)})}
        </Row>

      </form>
    </>
  );
};


export default Foodredact;
