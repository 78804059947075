import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Loading from "../loading/Loading";
import { BASE_URL } from "../../baseurl";
import { useTranslation } from "react-i18next";
import useFetch from "../../hooks/useFetch";

const CategoryRedact = ({ language }) => {
  const [data, setData] = useState([]);
  const [showDelete, setShowDelete] = useState(false);
  const [deleteAccept, setDeleteAccept] = useState(false);
  const [clickedPictureId, setClickedPictureId] = useState(null);
  const [fileName, setFileName] = useState("ფაილი არ არის არჩეული");
  const [title, setTitle] = useState("");
  const [sort, setSort] = useState(Boolean);
  const [image, setImage] = useState(null);
  const [photo, setPhoto] = useState(null);
  const [load, setLoad] = useState(true);
  const { t } = useTranslation();
  useEffect(() => {
    let URL = `${BASE_URL}/api/categories?populate=*`;
    if (language === "1") {
      URL = `${BASE_URL}/api/categories?locale=en&populate=*`;
    } else if (language === "2") {
      URL = `${BASE_URL}/api/categories?locale=ru&populate=*`;
    }
    setLoad(true);

    fetch(URL)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setData(data);
        setLoad(false);
      })
      .catch((error) => {
        console.error("There was a problem fetching the data:", error);
        setLoad(false);
      });
  }, [language]);


  const deleteHandler = () => {
    setLoad(true);
    fetch(`${BASE_URL}/api/categories/${clickedPictureId}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        console.log("Item deleted:", data);
        setClickedPictureId(null);
        setShowDelete(false);
        setDeleteAccept(false);
        // Reload the data after deletion
        fetchCategories();
        setLoad(false);
      })
      .catch((error) => {
        console.error("There was a problem deleting the item:", error);
        setLoad(false);
      });
  };

  const fetchCategories = () => {
    let URL = `${BASE_URL}/api/categories?populate=*`;
    if (language === "1") {
      URL = `${BASE_URL}/api/categories?locale=en&populate=*`;
    } else if (language === "2") {
      URL = `${BASE_URL}/api/categories?locale=ru&populate=*`;
    }

    setLoad(true);

    fetch(URL)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setData(data);
        setLoad(false);
      })
      .catch((error) => {
        console.error("There was a problem fetching the data:", error);
        setLoad(false);
      });
  };

  const handleFileChange = (event) => {
    const files = event.target.files;
    if (files && files[0]) {
      setFileName(files[0].name);
      setImage(URL.createObjectURL(files[0]));
      setPhoto(files[0]);
    }
  };

  const handleSubmit = async () => {
    const formData = new FormData();
    let dt = {title: title, sort: sort, ...data}
    if (dt.title == "") {
      delete dt.title;
    }
    formData.append("data", JSON.stringify(dt));
    formData.append("files.img", photo);
    // console.log(dt.title)
    setLoad(true);
    setTitle("")
    const response = await fetch(
      `${BASE_URL}/api/categories/${clickedPictureId}`,
      {
        method: "PUT",
        body: formData,
      }
    );
    if (response.ok) {
      console.log("Category updated successfully!");
      setLoad(false);
      fetchCategories();
      setImage(null);
      setPhoto(null);
    } else {
      console.error("Error updating category:", response.statusText);
      setLoad(false);
      fetchCategories();
    }
  };
  

  const dataaaa = useFetch(`${BASE_URL}/api/logos?populate=*`);
  const logo = dataaaa?.data?.data;
  const img = logo?.map((e) => e.attributes.img.data.attributes.url);
  // console.log(sort)

  return (
    <div>
      {load && <Loading />}
      {deleteAccept && (
        <div
          style={{
            position: "fixed",
            zIndex: "1000",
            height: "100vh",
            width: "100vw",
            background: "#383636ab",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <div
            style={{
              position: "fixed",
              zIndex: "100",
              border: "1px solid #7f6d47",
              borderRadius: "10px",
              background: "white",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <p style={{ padding: "1.5rem" }}>
              გსურთ ნამდვილად პროდუქტის წაშლა?
            </p>
            <div
              style={{
                display: "flex",
                padding: "0 2rem",
                paddingTop: "0",
                paddingBottom: "1rem",
                justifyContent: "right",
              }}
            >
              <button
                onClick={() => deleteHandler()}
                style={{
                  padding: "0.3rem 1rem",
                  fontFamily: "GramatikaGeo",
                  cursor: "pointer",
                  border: "none",
                  borderRadius: "4px",
                  background: "#16ca16",
                  color: "white",
                  marginLeft: "10px",
                }}
              >
                დიახ
              </button>
              <button
                onClick={() => {
                  setDeleteAccept(false);
                  setShowDelete(false);
                  setClickedPictureId(null);
                }}
                style={{
                  padding: "0.3rem 1rem",
                  fontFamily: "GramatikaGeo",
                  cursor: "pointer",
                  border: "none",
                  borderRadius: "4px",
                  background: "red",
                  color: "white",
                  marginLeft: "10px",
                }}
              >
                არა
              </button>
            </div>
          </div>
        </div>
      )}
      <Container>
        <form>
          <Row>
            {data?.data?.map((item) => (
              <Col key={item.id}>
                <div className="redactcategory">
                  {showDelete && item.id === clickedPictureId ? (
                    <>
                    <input style={{borderRadius:'8px', border:"1px solid #7f6d47", padding:"8px 20px", width:"100%", fontFamily:"GramatikaGeo", marginBottom:'4px'}} placeholder={item.attributes.title} type="text" onChange={(e) => setTitle(e.target.value)} />
                    <select onChange={(e) => setSort(e.target.value)} style={{width:'100%', margin:'10px 0', borderRadius:'8px', padding:'0.4rem', border:"1px solid #7f6d47", fontFamily:'GramatikaGeo'}}>
                      <option style={{display:"none"}}>{item.attributes.sort === false ? "სასმელი" : "კერძი"}</option>
                      <option value="false">სასმელი</option>
                      <option value="true">კერძი</option>
                    </select>
                    </>
                    ) : (<p>{item?.attributes?.title}</p>)}
                  {clickedPictureId !== item.id ? (
                    <img
                      style={{
                        width: "150px",
                        height: "150px",
                        cursor: "pointer",
                      }}
                      className="circle"
                      src={`${BASE_URL}${item?.attributes?.img?.data?.attributes?.url == null ? img : item?.attributes?.img?.data?.attributes?.url}`}
                      alt={item.attributes.alt}
                    />
                  ) : (
                    <Col
                      className="uploaded"
                      onClick={() =>
                        document.querySelector(".input-field").click()
                      }
                    >
                      <input
                        type="file"
                        accept="image/*"
                        className="input-field"
                        hidden
                        onChange={handleFileChange}
                      />

                      {image ? (
                        <div className="uploaded-img-wrapper border-top-upload">
                          <img
                            style={{
                              width: "150px",
                              height: "150px",
                              cursor: "pointer",
                            }}
                            className="circle"
                            src={image}
                            width={300}
                            alt={fileName}
                          />
                        </div>
                      ) : (
                        <div className="uploaded-img-wrapper border-top-upload">
                          <img
                            style={{
                              width: "150px",
                              height: "150px",
                              cursor: "pointer",
                            }}
                            className="circle"
                            src={`${BASE_URL}${item?.attributes?.img?.data?.attributes?.url == null ? img : item?.attributes?.img?.data?.attributes?.url}`}
                            alt="Avatar"
                          />
                        </div>
                      )}
                    </Col>
                  )}
                  {item.id !== clickedPictureId && (
                    <button
                      onClick={() => {
                        setShowDelete(true);
                        setClickedPictureId(item.id);
                        setImage(null);
                      }}
                    >
                      {t("redact")}
                    </button>
                  )}
                  {showDelete && item.id === clickedPictureId && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <button
                        onClick={(event) => {
                          setDeleteAccept(true);
                          event.preventDefault();
                        }}
                        style={{
                          background: "red",
                          marginRight: "8px",
                          color: "#fff",
                          border: "none",
                          padding: "8px 10px",
                        }}
                      >
                        {t("delete")}
                      </button>
                      <button
                        style={{
                          background: "rgb(22, 202, 22)",
                          color: "#fff",
                          border: "none",
                        }}
                        onClick={() => {
                          setClickedPictureId(null);
                          handleSubmit();
                          setShowDelete(false);
                        }}
                      >
                        {t("save")}
                      </button>
                    </div>
                  )}
                </div>
              </Col>
            ))}
          </Row>
        </form>
      </Container>
    </div>
  );
};

export default CategoryRedact;
