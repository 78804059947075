import { Col, Container, Row } from "react-bootstrap";
import React, { useState } from "react";
import Logo from '../pictures/logo.svg';
import { useEffect } from 'react';
import { BASE_URL } from "../baseurl";
import useFetch from "../hooks/useFetch";


const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState('');
    const [isPasswordIncorrect, setIsPasswordIncorrect] = useState(true);
  
    function validateForm() {
      return email.length > 0 && password.length > 0;
    }
  
    useEffect(() => {
      if (localStorage.getItem('accessToken')) {
        window.location.href = `https://${window.location.host}/upload`;
      }
    }, []); // Removed the second argument
  
    function handleSubmit(event) {
        event.preventDefault();
        setIsSubmitting(true);
        setError('');
      
        fetch(`${BASE_URL}/api/auth/local`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            identifier: email,
            password: password
          })
        })
          .then(response => {
            if (!response.ok) {
              throw new Error(`HTTP error! Status: ${response.status}`);
            }
            return response.json();
          })
          .then(data => {
            setIsSubmitting(false);
            setIsPasswordIncorrect(true);
            const refreshToken = data.refreshToken; // Use refreshToken if available
            const accessToken = data.jwt;
            localStorage.setItem('refreshToken', refreshToken);
            localStorage.setItem('accessToken', accessToken);
            if (data.data === null) {
              setIsPasswordIncorrect(false);
            } else if (localStorage.getItem('accessToken')) {
              window.location.href = `https://${window.location.host}/upload`;
            }
          })
          .catch(error => {
            setIsSubmitting(false);
            setIsPasswordIncorrect(false);
            setError('შეცდომა: იმეილი ან პაროლი არასწორია');
          });
      }


      const {data, loading} = useFetch(`${BASE_URL}/api/logos?populate=*`)
      const logo = data?.data;
      const img  = logo?.map((e) => e?.attributes?.img?.data?.attributes?.url);


    return (
        <Container fluid>
            <Row>
                <Col className="d-flex jc-center al-center login">
                    <div className="margin-bottom">
                        <h1>მოგესალმებით</h1>
                    </div>
                    <div className="login-container">
                        <div className="logo-wrapper">
                        <img src={`${BASE_URL}${img}`} alt="logo" />
                        </div>
                        <form className="login-form" onSubmit={handleSubmit}>
                            <label htmlFor="email">იმეილი</label>
                            <input
                                autoFocus
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                className={isPasswordIncorrect ? '' : 'red'}
                            />
                            <label htmlFor="password">პაროლი</label>
                            <input
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                className={isPasswordIncorrect ? '' : 'red'}
                            />
                            <button
                                block="true"
                                size="lg"
                                type="submit"
                                disabled={!validateForm() || isSubmitting}
                            >
                                {isSubmitting ? 'იტვირთება...' : 'შესვლა'}
                            </button>
                            {error && <div className="error">{error}</div>}
                        </form>
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default Login