import React, { useEffect, useState } from "react";
import { Row, Col, Container } from "react-bootstrap";
import Arrowdown from "../../pictures/menu/arrowdown.svg";
import { MdCloudUpload, MdDelete } from "react-icons/md";
import { AiFillFileImage } from "react-icons/ai";
import { useTranslation } from "react-i18next";
// import Default from "../../pictures/default.jpg";
import useFetch from "../../hooks/useFetch";
import { BASE_URL } from "../../baseurl";
import Loading from "../loading";
const Uploadfoods = ({ setLoading, language }) => {
  const initialState = {
    selectedTitle: "",
    name: "",
    price: "",
    description: "",
    photo: null,
    selectedFoodCategory: "",
    image: null,
    isCategoryOpen: false,
    small: "",
    medium: "",
    big: "",
    smallprice: Number,
    mediumprice: Number,
    bigprice: Number,
    // anotherFeatureTitle: "",
    // anotherFeatureTitleTwo: "",
    // anotherFeatureTitleThree: "",
    // anotherFeaturePrice: "",
    // anotherFeaturePriceTwo: "",
    // anotherFeaturePriceThree: "",
    categorys: "",
  };

  const [sendData, setSendData] = useState(initialState);
  const [sendDataEn, setSendDataEn] = useState(initialState);
  const [sendDataRu, setSendDataRu] = useState(initialState);
  const [customLoad, setCustomLoad] = useState(false);
  const [fileName, setFileName] = useState("");
  const [isCategoryOpenEn, setIsCategoryOpenEn] = useState(false);
  const [selectedTitleEn, setSelectedTitleEn] = useState("");
  const [isCategoryOpenRu, setIsCategoryOpenRu] = useState(false);
  const [selectedTitleRu, setSelectedTitleRu] = useState("");
  // const [anotherFeatureTitle, setAnotherFeatureTitle] = useState('')
  // const [anotherFeatureTitleTwo, setAnotherFeatureTitleTwo] = useState('')
  // const [anotherFeatureTitleThree, setAnotherFeatureTitleThree] = useState('')
  // const [anotherFeaturePrice, setAnotherFeaturePrice] = useState('')
  // const [anotherFeaturePriceTwo, setAnotherFeaturePriceTwo] = useState('')
  // const [anotherFeaturePriceThree, setAnotherFeaturePriceThree] = useState('')
  const isSubmitting = false;

  let {
    selectedTitle,
    title,
    price,
    description,
    photo,
    selectedFoodCategory,
    image,
    isCategoryOpen,
    categorys,
    small,
    medium,
    big,
    smallprice,
    mediumprice,
    bigprice,
  } = sendData;

  useEffect(() => {
    console.table({
      selectedTitle,
      title,
      price,
      description,
      photo,
      selectedFoodCategory,
      image,
      isCategoryOpen,
      categorys,
      small,
      medium,
      big,
      smallprice,
      mediumprice,
      bigprice,
    });
  }, [
    selectedTitle,
    title,
    price,
    description,
    photo,
    selectedFoodCategory,
    image,
    isCategoryOpen,
    categorys,
    small,
    medium,
    big,
    smallprice,
    mediumprice,
    bigprice,
  ]);

  function validateForm() {
    return (
      selectedTitle !== "" ||
      selectedTitleEn !== "" ||
      selectedTitleRu !== ""
      // selectedFoodCategory !== "" &&
      // title !== "" &&
      // price !== ""
    );
  }

  let input = document.querySelectorAll("input");
  const { t } = useTranslation();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    let data = { ...sendData, hide: false };

    for (let i = 0; i < input.length; i++) {
      if (input[i].type === "text" || input[i].type === "number") {
        input[i].value = "";
      }
    }
    setCustomLoad(true);

    formData.append("data", JSON.stringify(data));
    if (photo === null) {
      // const defaultPhoto = await fetch(Default);
      // const defaultPhotoBlob = await defaultPhoto.blob();
      // formData.append("files.img", defaultPhotoBlob, "default_photo.jpg");
    } else {
      formData.append("files.img", photo);
    }

    const response = await fetch(`${BASE_URL}/api/foods`, {
      method: "POST",
      body: formData,
    });

    if (response.ok) {
      console.log("Category created successfully!");
      setCustomLoad(false);
      setSendData(initialState);
    } else {
      console.error("Error creating category:", response.statusText);
      setCustomLoad(false);
      setSendData(initialState);
    }
  };

  const handleSubmitEn = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    let data = { ...sendDataEn, locale: "en", hide: false };

    for (let i = 0; i < input.length; i++) {
      if (input[i].type === "text" || input[i].type === "number") {
        input[i].value = "";
      }
    }
    setCustomLoad(true);

    formData.append("data", JSON.stringify(data));
    if (photo === null) {
      // const defaultPhoto = await fetch(Default);
      // const defaultPhotoBlob = await defaultPhoto.blob();
      // formData.append("files.img", defaultPhotoBlob, "default_photo.jpg");
    } else {
      formData.append("files.img", photo);
    }

    const response = await fetch(`${BASE_URL}/api/foods`, {
      method: "POST",
      body: formData,
    });

    if (response.ok) {
      console.log("Category created successfully!");
      setCustomLoad(false);
      setSendDataEn(initialState);
      setSelectedTitleEn("");
    } else {
      console.error("Error creating category:", response.statusText);
      setCustomLoad(false);
      setSendDataEn(initialState);
      setSelectedTitleEn("");
    }
  };
  const handleSubmitRu = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    let data = { ...sendDataRu, locale: "ru", hide: false };

    for (let i = 0; i < input.length; i++) {
      if (input[i].type === "text" || input[i].type === "number") {
        input[i].value = "";
      }
    }
    setCustomLoad(true);

    formData.append("data", JSON.stringify(data));
    if (photo === null) {
      // const defaultPhoto = await fetch(Default);
      // const defaultPhotoBlob = await defaultPhoto.blob();
      // formData.append("files.img", defaultPhotoBlob, "default_photo.jpg");
    } else {
      formData.append("files.img", photo);
    }

    const response = await fetch(`${BASE_URL}/api/foods`, {
      method: "POST",
      body: formData,
    });

    if (response.ok) {
      console.log("Category created successfully!");
      setCustomLoad(false);
      setSendDataRu(initialState);
      setSelectedTitleRu("");
    } else {
      console.error("Error creating category:", response.statusText);
      setCustomLoad(false);
      setSendDataRu(initialState);
      setSelectedTitleRu("");
    }
  };

  // let API_URL = `${BASE_URL}/api/categories?populate=*`;
  //       if (language === '1') {
  //            API_URL = `${BASE_URL}/api/categories?locale=en&populate=*`;
  //           } else if (language === '2') {
  //            API_URL = `${BASE_URL}/api/categories?locale=ru&populate=*`;
  //       }
  //       let { data } = useFetch(API_URL);
  //       let dataa = [];
  //       if (data && data.data) {
  //         data.data.forEach(el => {
  //           dataa.push({title: el.attributes.title, link : el.attributes.link});
  //         });
  //       }

  const newArray = useFetch(`${BASE_URL}/api/categories`);
  const newArrayEn = useFetch(`${BASE_URL}/api/categories?locale=en`);
  const newArrayRu = useFetch(`${BASE_URL}/api/categories?locale=ru`);

  return (
    <>
      {customLoad ? <Loading /> : null}
      <form
        className="form"
        onSubmit={(event) => {
          handleSubmit(event);
          handleSubmitEn(event);
          handleSubmitRu(event);
        }}
      >
        {/* Geo */}
        <Container>
          {/* Name And Price */}
          <h1 style={{ color: "#000000", marginBottom: "10px" }}>ქართული</h1>
          <Row>
            <Col className="d-flex jc-end">
              <div>
                <label htmlFor="category">{t("title")}</label>
                <br />
                <input
                  type="text"
                  id="one"
                  onChange={(event) =>
                    setSendData((prevState) => ({
                      ...prevState,
                      title: event.target.value,
                    }))
                  }
                  name="category"
                />
              </div>
            </Col>
            <Col className="d-flex jc-end">
              <div>
                <label htmlFor="category">{t("cost")}</label>
                <br />
                <input
                  type="number"
                  step="0.01"
                  id="two"
                  onChange={(event) =>
                    setSendData((prevState) => ({
                      ...prevState,
                      price: event.target.value,
                    }))
                  }
                  name="category"
                />
              </div>
            </Col>
          </Row>

          {/* Category Choose */}
          <Row className="margin-top-60">
            <Col>
              <div>
                <label htmlFor="category">{t("categoryname")}</label>
                <br />
                <div className="wrapper">
                  <div
                    onClick={() =>
                      setSendData((prevState) => ({
                        ...prevState,
                        isCategoryOpen: !isCategoryOpen,
                      }))
                    }
                    className="select-btn"
                  >
                    <span>{selectedTitle}</span>
                    <i
                      className={
                        isCategoryOpen ? "arrowdown-open" : "arrowdown"
                      }
                    >
                      <img src={Arrowdown} alt="arrow" />
                    </i>
                  </div>
                  <div
                    className={
                      isCategoryOpen ? "content show-dropdown" : "content"
                    }
                  >
                    <ul className="options">
                      {newArray?.data?.data?.map((item, key) => (
                        <li
                          onClick={() => {
                            setSendData((prevState) => ({
                              ...prevState,
                              isCategoryOpen: !isCategoryOpen,
                            }));
                            setSendData((prevState) => ({
                              ...prevState,
                              selectedTitle: t(item.attributes.title),
                            }));
                            setSendData((prevState) => ({
                              ...prevState,
                              category: t(item.attributes.title),
                            }));
                            setSendData((prevState) => ({
                              ...prevState,
                              categorys: t(item.id),
                            }));
                          }}
                          key={key}
                          value={item.link}
                        >
                          {t(item.attributes.title)}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </Col>
          </Row>

          {/* Description */}
          <Row className="margin-top-60">
            <Col className="d-flex jc-end">
              <div>
                <label htmlFor="category">{t("productdescription")}</label>
                <br />
                <input
                  type="text"
                  id="three"
                  onChange={(event) =>
                    setSendData((prevState) => ({
                      ...prevState,
                      description: event.target.value,
                    }))
                  }
                  maxLength={110}
                  name="category"
                />
              </div>
            </Col>
          </Row>

          {/* დამატებითი ვარიაცია */}
          <Row className="margin-top-60">
            <Col className="d-flex jc-end">
              <div>
                <label htmlFor="category">{t("title")} SMALL</label>
                <br />
                <input
                  type="text"
                  id="one"
                  onChange={(event) =>
                    setSendData((prevState) => ({
                      ...prevState,
                      small: event.target.value,
                    }))
                  }
                  name="category"
                />
              </div>
            </Col>
            <Col className="d-flex jc-end">
              <div>
                <label htmlFor="category">{t("title")} MEDIUM</label>
                <br />
                <input
                  type="text"
                  id="one"
                  onChange={(event) =>
                    setSendData((prevState) => ({
                      ...prevState,
                      medium: event.target.value,
                    }))
                  }
                  name="category"
                />
              </div>
            </Col>
            <Col className="d-flex jc-end">
              <div>
                <label htmlFor="category">{t("title")} BIG</label>
                <br />
                <input
                  type="text"
                  id="one"
                  onChange={(event) =>
                    setSendData((prevState) => ({
                      ...prevState,
                      big: event.target.value,
                    }))
                  }
                  name="category"
                />
              </div>
            </Col>
          </Row>

          
          {/* ვარიაციების ღირებულება */}
          <Row className="margin-top-60">
            <Col className="d-flex jc-end">
              <div>
                <label htmlFor="category">{t("price")} SMALL</label>
                <br />
                <input
                  type="number"
                  step="0.01"
                  id="one"
                  onChange={(event) =>
                    setSendData((prevState) => ({
                      ...prevState,
                      smallprice: event.target.value,
                    }))
                  }
                  name="category"
                />
              </div>
            </Col>
            <Col className="d-flex jc-end">
              <div>
                <label htmlFor="category">{t("price")} MEDIUM</label>
                <br />
                <input
                  type="number"
                  step="0.01"
                  id="one"
                  onChange={(event) =>
                    setSendData((prevState) => ({
                      ...prevState,
                      mediumprice: event.target.value,
                    }))
                  }
                  name="category"
                />
              </div>
            </Col>
            <Col className="d-flex jc-end">
              <div>
                <label htmlFor="category">{t("price")} BIG</label>
                <br />
                <input
                  type="number"
                  step="0.01"
                  id="one"
                  onChange={(event) =>
                    setSendData((prevState) => ({
                      ...prevState,
                      bigprice: event.target.value,
                    }))
                  }
                  name="category"
                />
              </div>
            </Col>
          </Row>

        </Container>

        {/* Eng */}
        <Container style={{ marginTop: "60px" }}>
          {/* Name And Price */}
          <h1 style={{ color: "#000000", marginBottom: "10px" }}>English</h1>
          <Row>
            <Col className="d-flex jc-end">
              <div>
                <label htmlFor="category">{t("title")}</label>
                <br />
                <input
                  type="text"
                  id="one"
                  onChange={(event) =>
                    setSendDataEn((prevState) => ({
                      ...prevState,
                      title: event.target.value,
                    }))
                  }
                  name="category"
                />
              </div>
            </Col>
            <Col className="d-flex jc-end">
              <div>
                <label htmlFor="category">{t("cost")}</label>
                <br />
                <input
                  type="number"
                  step="0.01"
                  id="two"
                  onChange={(event) =>
                    setSendDataEn((prevState) => ({
                      ...prevState,
                      price: event.target.value,
                    }))
                  }
                  name="category"
                />
              </div>
            </Col>
          </Row>

          {/* Category Choose */}
          <Row className="margin-top-60">
            <Col>
              <div>
                <label htmlFor="category">{t("categoryname")}</label>
                <br />
                <div className="wrapper">
                  <div
                    onClick={() =>
                      // setSendDataEn((prevState) => ({
                      //   ...prevState,
                      //   isCategoryOpenEn: !isCategoryOpenEn,
                      // }))
                      setIsCategoryOpenEn(!isCategoryOpenEn)
                    }
                    className="select-btn"
                  >
                    <span>{selectedTitleEn}</span>
                    <i
                      className={
                        isCategoryOpenEn ? "arrowdown-open" : "arrowdown"
                      }
                    >
                      <img src={Arrowdown} alt="arrow" />
                    </i>
                  </div>
                  <div
                    className={
                      isCategoryOpenEn ? "content show-dropdown" : "content"
                    }
                  >
                    <ul className="options">
                      {newArrayEn?.data?.data?.map((item, key) => (
                        <li
                          onClick={() => {
                            // setSendDataEn((prevState) => ({
                            //   ...prevState,
                            //   isCategoryOpenEn: !isCategoryOpenEn,
                            // }));
                            setIsCategoryOpenEn(!isCategoryOpenEn);
                            // setSendDataEn((prevState) => ({
                            //   ...prevState,
                            //   selectedTitleEn: t(item.attributes.title),
                            // }));
                            setSelectedTitleEn(t(item.attributes.title));
                            setSendDataEn((prevState) => ({
                              ...prevState,
                              category: t(item.attributes.title),
                            }));
                            setSendDataEn((prevState) => ({
                              ...prevState,
                              categorys: t(item.id),
                            }));
                          }}
                          key={key}
                          value={item.link}
                        >
                          {t(item.attributes.title)}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </Col>
          </Row>

          {/* Description */}
          <Row className="margin-top-60">
            <Col className="d-flex jc-end">
              <div>
                <label htmlFor="category">{t("productdescription")}</label>
                <br />
                <input
                  type="text"
                  id="three"
                  onChange={(event) =>
                    setSendDataEn((prevState) => ({
                      ...prevState,
                      description: event.target.value,
                    }))
                  }
                  maxLength={110}
                  name="category"
                />
              </div>
            </Col>
          </Row>


                      {/* დამატებითი ვარიაცია */}
          <Row className="margin-top-60">
            <Col className="d-flex jc-end">
              <div>
                <label htmlFor="category">{t("title")} SMALL</label>
                <br />
                <input
                  type="text"
                  id="one"
                  onChange={(event) =>
                    setSendDataEn((prevState) => ({
                      ...prevState,
                      small: event.target.value,
                    }))
                  }
                  name="category"
                />
              </div>
            </Col>
            <Col className="d-flex jc-end">
              <div>
                <label htmlFor="category">{t("title")} MEDIUM</label>
                <br />
                <input
                  type="text"
                  id="one"
                  onChange={(event) =>
                    setSendDataEn((prevState) => ({
                      ...prevState,
                      medium: event.target.value,
                    }))
                  }
                  name="category"
                />
              </div>
            </Col>
            <Col className="d-flex jc-end">
              <div>
                <label htmlFor="category">{t("title")} BIG</label>
                <br />
                <input
                  type="text"
                  id="one"
                  onChange={(event) =>
                    setSendDataEn((prevState) => ({
                      ...prevState,
                      big: event.target.value,
                    }))
                  }
                  name="category"
                />
              </div>
            </Col>
          </Row>

               {/* ვარიაციების ღირებულება */}
               <Row className="margin-top-60">
            <Col className="d-flex jc-end">
              <div>
                <label htmlFor="category">{t("price")} SMALL</label>
                <br />
                <input
                  type="number"
                  step="0.01"
                  id="one"
                  onChange={(event) =>
                    setSendDataEn((prevState) => ({
                      ...prevState,
                      smallprice: event.target.value,
                    }))
                  }
                  name="category"
                />
              </div>
            </Col>
            <Col className="d-flex jc-end">
              <div>
                <label htmlFor="category">{t("price")} MEDIUM</label>
                <br />
                <input
                  type="number"
                  step="0.01"
                  id="one"
                  onChange={(event) =>
                    setSendDataEn((prevState) => ({
                      ...prevState,
                      mediumprice: event.target.value,
                    }))
                  }
                  name="category"
                />
              </div>
            </Col>
            <Col className="d-flex jc-end">
              <div>
                <label htmlFor="category">{t("price")} BIG</label>
                <br />
                <input
                  type="number"
                  step="0.01"
                  id="one"
                  onChange={(event) =>
                    setSendDataEn((prevState) => ({
                      ...prevState,
                      bigprice: event.target.value,
                    }))
                  }
                  name="category"
                />
              </div>
            </Col>
          </Row>


        </Container>

        {/* Ru */}
        <Container style={{ marginTop: "60px" }}>
          {/* Name And Price */}
          <h1 style={{ color: "#000000", marginBottom: "10px" }}>Russian</h1>
          <Row>
            <Col className="d-flex jc-end">
              <div>
                <label htmlFor="category">{t("title")}</label>
                <br />
                <input
                  type="text"
                  id="one"
                  onChange={(event) =>
                    setSendDataRu((prevState) => ({
                      ...prevState,
                      title: event.target.value,
                    }))
                  }
                  name="category"
                />
              </div>
            </Col>
            <Col className="d-flex jc-end">
              <div>
                <label htmlFor="category">{t("cost")}</label>
                <br />
                <input
                  type="number"
                  step="0.01"
                  id="two"
                  onChange={(event) =>
                    setSendDataRu((prevState) => ({
                      ...prevState,
                      price: event.target.value,
                    }))
                  }
                  name="category"
                />
              </div>
            </Col>
          </Row>

          {/* Category Choose */}
          <Row className="margin-top-60">
            <Col>
              <div>
                <label htmlFor="category">{t("categoryname")}</label>
                <br />
                <div className="wrapper">
                  <div
                    onClick={() =>
                      // setSendDataRu((prevState) => ({
                      //   ...prevState,
                      //   isCategoryOpenEn: !isCategoryOpenEn,
                      // }))
                      setIsCategoryOpenRu(!isCategoryOpenRu)
                    }
                    className="select-btn"
                  >
                    <span>{selectedTitleRu}</span>
                    <i
                      className={
                        isCategoryOpenRu ? "arrowdown-open" : "arrowdown"
                      }
                    >
                      <img src={Arrowdown} alt="arrow" />
                    </i>
                  </div>
                  <div
                    className={
                      isCategoryOpenRu ? "content show-dropdown" : "content"
                    }
                  >
                    <ul className="options">
                      {newArrayRu?.data?.data?.map((item, key) => (
                        <li
                          onClick={() => {
                            // setSendDataRu((prevState) => ({
                            //   ...prevState,
                            //   isCategoryOpenEn: !isCategoryOpenEn,
                            // }));
                            setIsCategoryOpenRu(!isCategoryOpenRu);
                            // setSendDataRu((prevState) => ({
                            //   ...prevState,
                            //   selectedTitleEn: t(item.attributes.title),
                            // }));
                            setSelectedTitleRu(t(item.attributes.title));
                            setSendDataRu((prevState) => ({
                              ...prevState,
                              category: t(item.attributes.title),
                            }));
                            setSendDataRu((prevState) => ({
                              ...prevState,
                              categorys: t(item.id),
                            }));
                          }}
                          key={key}
                          value={item.link}
                        >
                          {t(item.attributes.title)}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </Col>
          </Row>

          {/* Description */}
          <Row className="margin-top-60">
            <Col className="d-flex jc-end">
              <div>
                <label htmlFor="category">{t("productdescription")}</label>
                <br />
                <input
                  type="text"
                  id="three"
                  onChange={(event) =>
                    setSendDataRu((prevState) => ({
                      ...prevState,
                      description: event.target.value,
                    }))
                  }
                  maxLength={110}
                  name="category"
                />
              </div>
            </Col>
          </Row>

    {/* დამატებითი ვარიაცია */}
    <Row className="margin-top-60">
            <Col className="d-flex jc-end">
              <div>
                <label htmlFor="category">{t("title")} SMALL</label>
                <br />
                <input
                  type="text"
                  id="one"
                  onChange={(event) =>
                    setSendDataRu((prevState) => ({
                      ...prevState,
                      small: event.target.value,
                    }))
                  }
                  name="category"
                />
              </div>
            </Col>
            <Col className="d-flex jc-end">
              <div>
                <label htmlFor="category">{t("title")} MEDIUM</label>
                <br />
                <input
                  type="text"
                  id="one"
                  onChange={(event) =>
                    setSendDataRu((prevState) => ({
                      ...prevState,
                      medium: event.target.value,
                    }))
                  }
                  name="category"
                />
              </div>
            </Col>
            <Col className="d-flex jc-end">
              <div>
                <label htmlFor="category">{t("title")} BIG</label>
                <br />
                <input
                  type="text"
                  id="one"
                  onChange={(event) =>
                    setSendDataRu((prevState) => ({
                      ...prevState,
                      big: event.target.value,
                    }))
                  }
                  name="category"
                />
              </div>
            </Col>
          </Row>

               {/* ვარიაციების ღირებულება */}
               <Row className="margin-top-60">
            <Col className="d-flex jc-end">
              <div>
                <label htmlFor="category">{t("price")} SMALL</label>
                <br />
                <input
                  type="number"
                  step="0.01"
                  id="one"
                  onChange={(event) =>
                    setSendDataRu((prevState) => ({
                      ...prevState,
                      smallprice: event.target.value,
                    }))
                  }
                  name="category"
                />
              </div>
            </Col>
            <Col className="d-flex jc-end">
              <div>
                <label htmlFor="category">{t("price")} MEDIUM</label>
                <br />
                <input
                  type="number"
                  step="0.01"
                  id="one"
                  onChange={(event) =>
                    setSendDataRu((prevState) => ({
                      ...prevState,
                      mediumprice: event.target.value,
                    }))
                  }
                  name="category"
                />
              </div>
            </Col>
            <Col className="d-flex jc-end">
              <div>
                <label htmlFor="category">{t("price")} BIG</label>
                <br />
                <input
                  type="number"
                  step="0.01"
                  id="one"
                  onChange={(event) =>
                    setSendDataRu((prevState) => ({
                      ...prevState,
                      bigprice: event.target.value,
                    }))
                  }
                  name="category"
                />
              </div>
            </Col>
          </Row>

        </Container>

        {/* დამატებითი მოცულობები და თანხები */}
        {/* <Row className="margin-top-60 anotherfeatures">
          <div className="d-flex jc-center al-center">
            <h1>{t('anotherfeature')}</h1>
          </div>
          <Col className="d-flex jc-end">
            <div>
              <label htmlFor="category">{t('title')}</label>
              <br />
              <input type="four" id="fnameeee" onChange={(event) => setAnotherFeatureTitle(event.target.value)} name="category" />
            </div>
          </Col>
          <Col className="d-flex jc-end">
            <div className="cost-wrapper-upload">
              <label className="cost" htmlFor="category">
                {t('cost')}
              </label>
              <br />
              <input type="number" step="0.01" id="five" onChange={(event) => setAnotherFeaturePrice(event.target.value)} name="category" />
            </div>
          </Col>
          <div className="d-flex jc-center al-center">
            <h1>---</h1>
          </div>
          <Col className="d-flex jc-end">
            <div>
              <label htmlFor="category">{t('title')}</label>
              <br />
              <input type="text" id="fnameeeee" onChange={(event) => setAnotherFeatureTitleTwo(event.target.value)} name="category" />
            </div>
          </Col>
          <Col className="d-flex jc-end">
            <div className="cost-wrapper-upload">
              <label className="cost" htmlFor="category">
                {t('cost')}
              </label>
              <br />
              <input type="number" step="0.01" id="fnameeeeee" onChange={(event) => setAnotherFeaturePriceTwo(event.target.value)} name="category" />
            </div>
          </Col>
          <div className="d-flex jc-center al-center">
            <h1>---</h1>
          </div>
          <Col className="d-flex jc-end">
            <div>
              <label htmlFor="category">{t('title')}</label>
              <br />
              <input type="text" id="fnameeeeeeee" onChange={(event) => setAnotherFeatureTitleThree(event.target.value)} name="category" />
            </div>
          </Col>
          <Col className="d-flex jc-end">
            <div className="cost-wrapper-upload">
              <label className="cost" htmlFor="category">
                {t('cost')}
              </label>
              <br />
              <input type="number" step="0.01" id="fnameeeeeeeee" onChange={(event) => setAnotherFeaturePriceThree(event.target.value)} name="category" />
            </div>
          </Col>
        </Row> */}

        <Row className="margin-top-60">
          <Col
            className="uploaded"
            onClick={() => document.querySelector(".input-field").click()}
          >
            <input
              type="file"
              accept="image/*"
              className="input-field"
              hidden
              onChange={({ target: { files } }) => {
                files[0] && setFileName(files[0].name);
                if (files) {
                  setSendData((prevState) => ({
                    ...prevState,
                    image: URL.createObjectURL(files[0]),
                    photo: files[0],
                  }));
                }
              }}
            />

            {image ? (
              <div className="uploaded-img-wrapper border-top-upload">
                <img
                  className="border-radius-upload p-10"
                  src={image}
                  width={300}
                  alt={fileName}
                />
              </div>
            ) : (
              <>
                <div className="uploaded-img-wrapper border-top-upload">
                  <MdCloudUpload color="#7f6d47" size={60} />
                </div>
                <div className="uploaded-img-wrapper">
                  <p className="p-10">{t("upload")}</p>
                </div>
              </>
            )}
            <section className="uploaded-row">
              <AiFillFileImage color="#7f6d47" />
              <span className="upload-content">
                {fileName} -
                <MdDelete
                  color="#7f6d47"
                  onClick={() => {
                    setFileName(t("fileisnotchoosen"));
                    setSendData((prevState) => ({ ...prevState, image: null }));
                  }}
                />
              </span>
            </section>
          </Col>
        </Row>
        <Row className="margin-top-60 btn-wrapper-upload">
          <button
            disabled={!validateForm() || isSubmitting}
            className="shadow-upload-button"
          >
            {t("submit")}
          </button>
        </Row>
      </form>
    </>
  );
};

export default Uploadfoods;
