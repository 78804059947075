import { Container, Row, Col } from "react-bootstrap"
import miniLogo from '../../pictures/logo.png'
import Clock from '../../pictures/menu/clock.svg'
import Instagram from '../../pictures/menu/instagram.svg'
import Facebook from '../../pictures/menu/facebook.svg'
import { useTranslation } from "react-i18next"
import useFetch from "../../hooks/useFetch"
import { BASE_URL } from "../../baseurl"
import axios from 'axios'
import { useEffect, useState } from "react"



const Footer = ({language}) => {
    const [dataInform, setDataInform] = useState([]);
    const [loading, setLoading] = useState(true);
    let URLS = `${BASE_URL}/api/footercontents`;
    if (language === '1') {
        URLS = `${BASE_URL}/api/footercontents?locale=en`;
        } else if (language === '2') {
            URLS = `${BASE_URL}/api/footercontents?locale=ru`;
    }

    const fetchedData = async () => {
        try {
            const response = await axios.get(URLS)
            setDataInform(response?.data?.data);
        } catch(error) {
            // console.log(error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchedData();
    },[language])

    console.log(dataInform)


    const data = useFetch(`${BASE_URL}/api/logos?populate=*`)
          const logo = data?.data?.data;
          const img  = logo?.map((e) => e?.attributes?.img?.data?.attributes?.url);
    
    const {t} = useTranslation();

    return (
        <>
            <footer>
                <Container>
                    <Row xs={1} md={3}>
                        <Col>
                            <img src={`${BASE_URL}${img}`} alt='minilogo' />
                            <h1>{t('address')}</h1>
                            <h2>{dataInform?.map((el) => el?.attributes?.address)}<br /> {t('addresscondpart')}</h2>
                        </Col>
                        <Col>

                            <h1>{t('contact')}</h1>
                            <h2>{dataInform?.map((el) => el?.attributes.mobilenumb)}</h2>

                        </Col>
                        <Col className="lastcontainer">
                            <h1>{t('workhours')}</h1>
                            <h2><span><img src={Clock} className='clock' alt='clock' /></span>{dataInform?.map((el) => el?.attributes.workhours)}</h2>

                            <ul>
                                <li>
                                    <a href={dataInform?.map((el) => el?.attributes.fblink)} target="_blank" rel="noreferrer"><img src={Instagram} alt="instagram" /></a>
                                </li>
                                <li>
                                    <a href={dataInform?.map((el) => el?.attributes.iglink)} target="_blank" rel="noreferrer"><img src={Facebook} alt="facebook" /></a>
                                </li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </footer>
            <Container fluid>
                <h1>{t('rights')}</h1>
            </Container>
        </>
    )
}

export default Footer