import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  ge: {
    translation: {
      menu: "მენიუ",
      foods: "კერძები",
      beverage: "სასმელი",
      restaurant: "არტიზანული საცხობი",
      restname: "ბატონები",
      all: "ყველა",
      contact: "დაგვიკავშირდით",
      address: "მისამართი",
      addresfirstpart: "ი.ჭავჭავაძის N54",
      addresscondpart: "",
      workhours: "სამუშაო საათები",
      rights: "ყველა უფლება დაცულია 2023",
      addcard: "დამატება",
      deletefromcart: "კალათიდან წაშლა",
      tax: "ჯამი მოიცავს მომსახურეობის საკომისიოს +",
      fresh: "ჯანსაღი",
      international: "ინტერნაციონალური",
      stake: "სტეიკი",
      vegy: "ვეგეტარიანული",
      soup: "წვნიანი",
      boar:'დაფა',
      sendvich: "სენდვიჩი",
      salad: "სალათი",
      kebab: "ქაბაბი",
      pizza: "პიცა",
      lunch: "ლანჩი",
      japan: "იაპონური",
      coctails: "კოქტეილები",
      soda: "გაზიანი",
      hotdrink: "ცხელი სასმელები",
      natural: "ნატურალური",
      nonalco: "უალკოჰოლო",
      wine: "ღვინო",
      croissant: "კრუასანი",
      cinnamon: "სინამონ როლი",
      bun: "ფუნთუშა",
      khada: "ქადა",
      bakery: "ცომეული",
      sandwich: "სენდვიჩი",
      wrap: "ვრაპი",
      biscuitecake: "კექსი",
      dessert: "დესერტი",
      cake: "ნამცხვარი",
      breakfast: "საუზმე",
      tea: "ჩაი",
      coffee: "ყავა",
      appetize:'ხემსი',
      beer: "ლუდი",
      alcoholic: "ალკოჰოლი",
      energyballs: "ენერგო ბურთულა",
      softdrink: "გამაგრილებელი სასმელი",
      mineraldrinks: "მინერალური წყალი",
      productdescription: "პროდუქტის აღწერა",
      title: "დასახელება",
      cost: "ღირებულება",
      categoryname: "კატეგორიის სახელი",
      anotherfeature: "კერძის / სასმლის ვარიაციები",
      upload: "სურათის ატვირთვა",
      fileisnotchoosen: "ფაილი არ არის არჩეული",
      submit: "დამატება",
      search: "ძებნა",
      addCategory: "კატეგორიის დამატება",
      addProduct: "პროდუქტის დამატება",
      productRedact: "პროდუქტის რედაქტირება",
      redact: "რედაქტირება",
      save: "დამახსოვრება",
      delete: "პროდუქტის წაშლა",
      currency: "₾",
      big:'დიდი',
      medium:'საშუალო',
      small:'პატარა',
      price:'თანხა',
      tothemain:'საიტზე დაბრუნება',
      categoryRedact:'კატეგორიის რედაქტირება',
      herosection:"ჰერო სურათის შეცვლა",
      logochange:"ლოგოს შეცვლა",
      percentageChange:"პროცენტის შეცვლა",
      status:'სტატუსი',
      show:'ჩანს',
      hide:'დამალვა',
      tobefilled:"შესავსებია",
      content:"ინფორმაციის განახლება",
      Mobilenumb:"მობილურის ნომერი",
      Workhours:"სამუშაო საათები",
      fb:"Facebook",
      inst:"Instagram",
      firstline:"რესტორნის დასახელება პირველი ხაზი",
      secondline:"რესტორნის დასახელება მეორე ხაზი",
      hideden:"არ ჩანს"
    },
  },
  en: {
    translation: {
      menu: "Menu",
      foods: "Foods",
      beverage: "Beverage",
      restaurant: "Artisan Bakery",
      restname: "Batonebi",
      all: "All",
      contact: "Contact us",
      address: "Address",
      addresfirstpart: "I. Chavchavadze N54",
      addresscondpart: "",
      workhours: "Working hours",
      rights: "All Rights Reserved 2023",
      addcard: "Add to wishlist",
      deletefromcart: "Delete from wishlist",
      tax: "Total includes service fee + 10%",
      fresh: "Fresh",
      appetize:'Appetizer',
      international: "International",
      stake: "Stake",
      vegy: "Vegy",
      soup: "Soup",
      sendvich: "Sendvich",
      cinnamon: "Cinnamon Roll",
      bun: "Bun",
      boar:'Board',
      beer: "Beer",
      khada: "Khada",
      bakery: "Bakery",
      sandwich: "Sandwich",
      wrap: "Wrap",
      salad: "Salad",
      biscuitecake: "Biscuit Cake",
      dessert: "Dessert",
      cake: "Cake",
      breakfast: "Breakfast",
      tea: "Tea",
      coffee: "Coffee",
      energyballs: "Energy Balls",
      kebab: "Kebab",
      pizza: "Pizza",
      lunch: "Lunch",
      japan: "Japan",
      coctails: "Coctails",
      soda: "Soda",
      alcoholic: "alcohol",
      hotdrink: "Hot Drinks",
      natural: "Natural Drinks",
      nonalco: "Soft Drink",
      wine: "Wine",
      croissant: "Croissant",
      mineraldrinks: "Mineral Drinks",
      productdescription: "Product Description",
      title: "Title",
      cost: "Cost",
      categoryname: "Category name",
      anotherfeature: "Foods / Beverage variations",
      upload: "Upload picture",
      fileisnotchoosen: "File is not choosen",
      submit: "Add",
      search: "Search",
      addCategory: "Add Category",
      addProduct: "Add Product",
      productRedact: "Product Redact",
      redact: "Editing",
      save: "Save",
      delete: "Delete Product",
      currency: "GEL",
      big:'Big',
      medium:'Medium',
      small:'Small',
      price:'Price',
      tothemain:'Return to site',
      categoryRedact:'Edit Category',
      herosection:"Hero IMG Change",
      logochange:"Change logo",
      percentageChange:"Change Percentage",
      status:'Status',
      show:'Show',
      hide:'Hide',
      tobefilled:"To be filled",
      content:"Update Information",
      Mobilenumb:"Phone Number",
      Workhours:"Working Hours",
      fb:"Facebook",
      inst:"Instagram",
      firstline:"First line",
      secondline:"Second line",
      hideden:"Hidden",
    },
  },
  ru: {
    translation: {
      menu: "Меню",
      foods: "Еда",
      beverage: "Напитки",
      restaurant: "Artisan Bakery",
      restname: "Batonebi",
      all: "Все",
      boar:'Доска',
      appetize:'Закуска',
      contact: "Связаться с нами",
      address: "Адрес",
      cinnamon: "Синамон ролл",
      bun: "Булочка",
      khada: "Када",
      bakery: "Печеный",
      sandwich: "Сэндвич",
      wrap: "Врап",
      addresfirstpart: "И. Чавчавадзе N54",
      addresscondpart: "",
      workhours: "Рабочее время",
      rights: "Все права защищены 2023",
      addcard: "Добавить в список желаний",
      deletefromcart: "Удалить из списка желаний",
      tax: "Итого включает сервисный сбор + 10%",
      fresh: "Свежий",
      international: "Международный",
      stake: "Стейк",
      vegy: "Веги",
      soup: "Супи",
      sendvich: "сендвич",
      salad: "Салат",
      biscuitecake: "Кекс",
      dessert: "Десерт",
      cake: "Пирожное",
      breakfast: "Завтрак",
      tea: "Чай",
      coffee: "Кофе",
      beer: "Пиво",
      alcoholic: "алкоголь",
      energyballs: "Энергетические шары",
      kebab: "Кебаб",
      pizza: "Пицца",
      lunch: "Обед",
      japan: "Япония",
      coctails: "Коктейли",
      soda: "Сода",
      hotdrink: "Горячие напитки",
      natural: "Натуральные напитки",
      nonalco: "Безалкогольный",
      wine: "Вино",
      croissant: "Круассан",
      mineraldrinks: "Минеральные напитки",
      productdescription: "Описание продукта",
      title: "Имя",
      cost: "Цeнa",
      categoryname: "Название категории",
      anotherfeature: "Вариации продуктов питания / напитков",
      upload: "Загрузить файл",
      fileisnotchoosen: "Файл не загружен",
      submit: "Загрузить",
      search: "Поиск",
      addCategory: "Добавить категорию",
      addProduct: "Добавить продукт",
      productRedact: "Изменить продукт",
      redact: "Редактирование",
      save: "Сохранять",
      delete: "Удалить продукт",
      currency: "Лари",
      big:'Большой',
      medium:'Средний',
      small:'Маленький',
      price:'Цена',
      tothemain:'Вернуться на сайт',
      categoryRedact:'Изменить категория',
      herosection:"HeroSection Image",
      logochange:"Change logo",
      percentageChange:"Change Percentage",
      status:'положение дел',
      show:'показывать',
      hide:'скрывать',
      tobefilled:"должны быть заполнены",
      content:"Обновить информацию",
      Mobilenumb:"Номер телефона",
      Workhours:"Рабочее время",
      fb:"Facebook",
      inst:"Instagram",
      firstline:"Первая линия",
      secondline:"Вторая линия",
      hideden:"невидимый"
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "ge",

  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
