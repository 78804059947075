import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Loading from "../loading/Loading";
import { BASE_URL } from "../../baseurl";
import { useTranslation } from "react-i18next";

const HeroSectPhoto = ({language}) => {
    const [data, setData] = useState([]);
    const [showDelete, setShowDelete] = useState(false);
    const [deleteAccept, setDeleteAccept] = useState(false);
    const [clickedPictureId, setClickedPictureId] = useState(null);
    const [fileName, setFileName] = useState("ფაილი არ არის არჩეული");
    const [image, setImage] = useState(null);
    const [photo, setPhoto] = useState(null);
    const [load, setLoad] = useState(true);
    const [hide, setHide] = useState(true);
    const {t} = useTranslation();
    useEffect(() => {
      let URL = `${BASE_URL}/api/herosections?populate=*`;
      if (language === "1") {
        URL = `${BASE_URL}/api/herosections?locale=en&populate=*`;
      } else if (language === "2") {
        URL = `${BASE_URL}/api/herosections?locale=ru&populate=*`;
      }
  
      fetch(URL)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          setData(data);
          setLoad(false)
        })
        .catch((error) => {
          console.error("There was a problem fetching the data:", error);
        });
  
        
    }, [language]);
  
    const deleteHandler = () => {
      setLoad(true)
      fetch(`${BASE_URL}/api/herosections/${clickedPictureId}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          setLoad(false)
          fetchCategories()
          return response.json();
        })
        .then((data) => {
          console.log("Item deleted:", data);
          setClickedPictureId(null);
          setShowDelete(false);
          setDeleteAccept(false);
          setLoad(false)
          // Reload the data after deletion
          fetchCategories();
        })
        .catch((error) => {
          console.error("There was a problem deleting the item:", error);
        });
    };
  
    const fetchCategories = () => {
      let URL = `${BASE_URL}/api/herosections?populate=*`;
      if (language === "1") {
        URL = `${BASE_URL}/api/herosections?locale=en&populate=*`;
      } else if (language === "2") {
        URL = `${BASE_URL}/api/herosections?locale=ru&populate=*`;
      }
  
      fetch(URL)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          setData(data);
        })
        .catch((error) => {
          console.error("There was a problem fetching the data:", error);
        });
    };
  
    const handleFileChange = (event) => {
      const files = event.target.files;
      if (files && files[0]) {
        setFileName(files[0].name);
        setImage(URL.createObjectURL(files[0]));
        setPhoto(files[0]);
      }
    };
  
    const handleSubmit = async () => {
      const formData = new FormData();
      formData.append("data", JSON.stringify({ ...data, hide: hide }));
      formData.append("files.img", photo);
      setLoad(true)
      const response = await fetch(`${BASE_URL}/api/herosections/${clickedPictureId}`, {
        method: "PUT",
        body: formData,
      });
      if (response.ok) {
        console.log("Category created successfully!");
        setLoad(false)
        fetchCategories()
      } else {
        setLoad(false)
        console.error("Error creating category:", response.statusText);
        fetchCategories()
      }
    };
  
  //   setTimeout(() => {
  //     setLoad(false);
  //   }, 1000);
  
    // console.log(data?.data?.map((e) => e.attributes.herosection.data.attributes.url))
  
    return (
      <div>
          {load && <Loading />}
        {deleteAccept && (
          <div
            style={{
              position: "fixed",
              zIndex: "1000",
              height: "100vh",
              width: "100vw",
              background: "#383636ab",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <div
              style={{
                position: "fixed",
                zIndex: "100",
                border: "1px solid #7f6d47",
                borderRadius: "10px",
                background: "white",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <p style={{ padding: "1.5rem" }}>
                გსურთ ნამდვილად პროდუქტის წაშლა?
              </p>
              <div
                style={{
                  display: "flex",
                  padding: "0 2rem",
                  paddingTop: "0",
                  paddingBottom: "1rem",
                  justifyContent: "right",
                }}
              >
                <button
                  onClick={() => deleteHandler()}
                  style={{
                    padding: "0.3rem 1rem",
                    fontFamily: "GramatikaGeo",
                    cursor: "pointer",
                    border: "none",
                    borderRadius: "4px",
                    background: "#16ca16",
                    color: "white",
                    marginLeft: "10px",
                  }}
                >
                  დიახ
                </button>
                <button
                  onClick={() => {
                    setDeleteAccept(false);
                    setShowDelete(false);
                    setClickedPictureId(null);
                  }}
                  style={{
                    padding: "0.3rem 1rem",
                    fontFamily: "GramatikaGeo",
                    cursor: "pointer",
                    border: "none",
                    borderRadius: "4px",
                    background: "red",
                    color: "white",
                    marginLeft: "10px",
                  }}
                >
                  არა
                </button>
              </div>
            </div>
          </div>
        )}
        <Container>
          <form>
            <Row>
              {data.data?.map((item) => (
                <Col key={item.id}>
                  <div className="redactcategory">
                    <p>{item.attributes.title}</p>
                    {clickedPictureId !== item.id ? (
                      <img
                        style={{ width: "150px", height: "150px", cursor: "pointer" }}
                        className="circle"
                        src={`${BASE_URL}${item.attributes.img.data.attributes.url}`}
                        alt={item.attributes.alt}
                      />
                    ) : (
                      <Col
                        className="uploaded"
                        onClick={() => document.querySelector(".input-field").click()}
                      >
                        <input
                          type="file"
                          accept="image/*"
                          className="input-field"
                          hidden
                          onChange={handleFileChange}
                        />
  
                        {image ? (
                          <div className="uploaded-img-wrapper border-top-upload">
                            <img
                              style={{ width: "150px", height: "150px", cursor: "pointer" }}
                              className="circle"
                              src={image}
                              width={300}
                              alt={fileName}
                            />
                          </div>
                        ) : (
                          <div className="uploaded-img-wrapper border-top-upload">
                            <img
                              style={{ width: "150px", height: "150px", cursor: "pointer" }}
                              className="circle"
                              src={`${BASE_URL}${item.attributes.img.data.attributes.url}`}
                              alt="Avatar"
                            />
                          </div>
                        )}
                      </Col>
                    )}
                    {item.id !== clickedPictureId && <button onClick={() => {setShowDelete(true); setClickedPictureId(item.id); setImage(null)}}>{t("redact")}</button>}
                    {showDelete && item.id === clickedPictureId && 
                    <div style={{display:'flex', alignItems:"center", justifyContent:'space-between'}}>
                      {/* <button onClick={(event) => {setDeleteAccept(true); event.preventDefault()}} style={{background:'red', marginRight:'8px', color:'#fff', border:'none', padding:'8px 10px'}}>წაშლა</button> */}
                      <button onClick={(event) => {setHide(!hide); event.preventDefault()}} style={{background:'red', marginRight:'8px', color:'#fff', border:'none', padding:'8px 10px'}}>{hide === true ? "დამალვა" : "გამოჩენა"}</button>
                      <button style={{background:'rgb(22, 202, 22)', color:'#fff', border:'none'}} onClick={() => {setLoad(true); setClickedPictureId(null); handleSubmit(); setShowDelete(false)}}>{t("save")}</button>
                    </div>
                    }
                  </div>
                </Col>
              ))}
            </Row>
          </form>
        </Container>
      </div>
    );
  };

export default HeroSectPhoto