import { useEffect, useState } from 'react';
import { BASE_URL } from '../../baseurl';
import useFetch from '../../hooks/useFetch';
import Restaurant from '../../pictures/hero.jpg'
import { useTranslation } from 'react-i18next';
import axios from 'axios';
const HeroSection = ({language}) => {
    const [dataInform, setDataInform] = useState([]);
    const [loading, setLoading] = useState(true);
    let URLS = `${BASE_URL}/api/footercontents`;
    if (language === '1') {
        URLS = `${BASE_URL}/api/footercontents?locale=en`;
        } else if (language === '2') {
            URLS = `${BASE_URL}/api/footercontents?locale=ru`;
    }

    const fetchedData = async () => {
        try {
            const response = await axios.get(URLS)
            setDataInform(response?.data?.data);
        } catch(error) {
            // console.log(error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchedData();
    },[language])
    const data = useFetch(`${BASE_URL}/api/herosections?populate=*`);
    
    const result = data?.data?.data?.map((e) => e.attributes);
    const imageResult = result?.map((e) => e.img)
    const hid = result?.map((e) => e.hide)

    const { t } = useTranslation();

    return (
        <>
            {hid == "false" ? "" : <div className="hero-section">
                <div>
                    <h1>
                        {dataInform?.map((el) => el?.attributes?.restnameone)}
                        <br /><span>{dataInform?.map((el) => el?.attributes?.restnametwo)}</span>
                    </h1>
                </div>
                <img src={`${BASE_URL}${imageResult?.map((e) => e.data.attributes.url)}`} alt='restaurant' />
            </div>}
        </>
    )
}

export default HeroSection