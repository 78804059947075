// import React from "react";

// const Loading = ({loading}) => {
//   return (
//     <div className={loading ? 'body loading' : 'body'}>
//       <div className="spinner-square">
//         <div className="square-1 square"></div>
//         <div className="square-2 square"></div>
//         <div className="square-3 square"></div>
//       </div>
//     </div>
//   );
// };

// export default Loading;


import { Container } from "react-bootstrap";

const Loading = () => {
  return (
    <div style={{position:"fixed", zIndex:"1000", height:"100vh", width:'100vw', background:'rgb(247 240 232 / 69%)', top:"50%", left:'50%', transform:'translate( -50%, -50%)'}}>
      <Container className="center" style={{position:'fixed', top:"50%", left:'50%', transform:'translate( -50%, -50%)', zIndex:"10000"}}>
        <div className="spinner-square">
          <div className="square-1 square"></div>
          <div className="square-2 square"></div>
          <div className="square-3 square"></div>
        </div>
      </Container>
    </div>
  );
};

export default Loading;

